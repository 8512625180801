import { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "moment/locale/pt-br";
import {
  repurposalsList,
  repurposalsTotalizers,
} from "../../services/supplier.service";
import { useSearchParams } from "react-router-dom";
import Status from "../../components/Status";
import { Sidebar } from "../../components/Sidebar";
import { SidebarButton } from "../../components/Sidebar/SidebarButton";
import { RepurposalsTable } from "./components/TableRepurposals";
import { RepurposalsCards } from "./components/CardsRepurposals";
import { CurrentConfigs } from "../../utils/externalPortalUtils";
import { eventGa } from "../../utils/eventClickGa";
import { useTimeout } from "usehooks-ts";
import { t } from "i18next";
import MonthFilter from "../../components/MonthFilter";

export function Repurposals() {
  const [repurposals, setRepurposals] = useState<RepurposalsResponse | null>(
    null
  );
  const configs = CurrentConfigs.get();
  const [coinType, setCoinType] = useState<string>("BRL");
  const [coinPrice, setCoinPrice] = useState<number>();

  const [totalizers, setTotalizers] = useState<TotalizersResponse | undefined>(
    undefined
  );
  const [month, setMonth] = useState<string>("");
  const [openMonth, setOpenMonth] = useState(false);
  const [status, setStatus] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page");
  const [page, setPage] = useState<string>(pageNumber ?? "1");

  useEffect(() => {
    setCoinType(configs?.convertionCoinType || "BRL");
    setCoinPrice(configs?.convertionCoinPrice);
  }, [configs]);

  useTimeout(() => {
    eventGa({
      label: localStorage.getItem("gaClient") || "Not defined",
      action: "acesso_pagina_recolocacoes",
    });
  }, 1000);

  function setPageAndUpdateUrl(newPage: string) {
    setPage(newPage);
    setSearchParams({ page: newPage });
  }

  async function fetchData() {
    setIsLoading(true);

    try {
      const repurposalsPromise = getRepurposals();
      const totalizersPromise = getTotalizers();
      const [repurposalsData, totalizersData] = await Promise.all([
        repurposalsPromise,
        totalizersPromise,
      ]);
      setRepurposals(repurposalsData);
      setTotalizers(totalizersData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function getRepurposals() {
    const params = {
      createdAtMonthYear: month,
      status: status,
      itemsPerPage: "20",
      pageNumber: String(page),
    };

    try {
      const response = await repurposalsList({ params });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function getTotalizers() {
    const params = {
      createdAtMonthYear: month,
      status: status,
    };
    try {
      const response = await repurposalsTotalizers({ params });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function handleStatus(value: string) {
    setStatus(value);
    setPageAndUpdateUrl("1");
  }

  useEffect(() => {
    fetchData();
  }, [status, page, month]);

  return (
    <Fragment>
      <Container>
        <Row className=" pb-4 align-items-center">
          <Col className="col-auto fs-4">
            {t("Repurposals.repurposals") as string}
          </Col>
          <Col className="d-flex justify-content-end gap-2">
            <Status handleSearch={handleStatus} selectedStatus={status} />{" "}
          </Col>
        </Row>
        <Row>
          <Col className="col-2">
            <Sidebar>
              <SidebarButton
                text={t("Global.filters.monthYear") as string}
                open={setOpenMonth}
                view={openMonth}
              />
              {openMonth && (
                <>
                  <MonthFilter
                    selectedMonth={5}
                    selectedYear={2023}
                    setMonth={setMonth}
                  />
                </>
              )}
            </Sidebar>
          </Col>
          <Col>
            <Row className="gap-3">
              <RepurposalsCards
                totalizers={totalizers}
                repurposals={repurposals}
                isLoading={isLoading}
                coinPrice={coinPrice}
                coinType={coinType}
              />
            </Row>
            <Row>
              <RepurposalsTable
                page={page}
                setPage={setPage}
                coinPrice={coinPrice}
                coinType={coinType}
                data={repurposals}
                isLoading={isLoading}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
