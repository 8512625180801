import React from "react";
import { Sidebar } from "../../../../components/Sidebar";
import { SidebarButton } from "../../../../components/Sidebar/SidebarButton";
import { Filters } from "../../../../components/Filters";
import { t } from "i18next";
import MonthFilter from "../../../../components/MonthFilter";

interface IPaymentsFilters {
  handleMonth: React.Dispatch<React.SetStateAction<string>>;
  handleTypePayments: React.Dispatch<React.SetStateAction<string>>;
}

export function PaymentsFilters(props: IPaymentsFilters) {
  const { handleTypePayments } = props;
  const [openMonth, setOpenMonth] = React.useState(false);

  const arrayTypePayments = [
    {
      list: "PIX",
      value: "1",
    },
    {
      list: t("Billing.attributes.bankSlip") as string,
      value: "2",
    },
    {
      list: "TED",
      value: "3",
    },
  ];

  return (
    <Sidebar>
      <Filters
        title={t("Billing.attributes.paymentMethod")}
        array={arrayTypePayments}
        handleValue={handleTypePayments}
      />
      <SidebarButton
        text={t("Global.filters.monthYear")}
        open={setOpenMonth}
        view={openMonth}
      />
      {openMonth && (
        <>
          <MonthFilter
            selectedMonth={5}
            selectedYear={2023}
            setMonth={props.handleMonth}
          />
        </>
      )}
    </Sidebar>
  );
}
