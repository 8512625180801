import { useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { LoadingComponent } from "../Loading";
import { BsSearch } from "react-icons/bs";

export function DropdownInput<T>(props: DropdownInputProps<T>) {
  const {
    onChange,
    options,
    renderTitle = () => "",
    renderName,
    isLoading,
    required,
    onClick,
    value,
    disabled,
    customFeedbackMessage,
    className = "",
    variant = "outline-light",
    selectIcon,
  } = props;

  const [search, setSearch] = useState<string>("");
  const [invalidSelection, setInvalidSelection] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLOptionElement>) => {
    setInvalidSelection(false);
    const newSelected = options[Number(e.currentTarget.value)];
    inputRef.current?.setCustomValidity("");
    onClick(newSelected);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    onChange(e);
    inputRef.current?.setCustomValidity("invalid");
  };

  return (
    <div className="d-flex flex-column w-100">
      <Dropdown>
        <Dropdown.Toggle
          variant={variant}
          className={`w-100 d-flex ${
            selectIcon
              ? "dropdown-input-button form-select"
              : "align-items-center"
          } justify-content-between focus-ring ${
            invalidSelection ? "focus-ring-danger border-danger" : "border"
          } ${className}`}
          data-testid={props["dropdown-data-testid"]}
        >
          {value ? renderName(value) : "Selecione"}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="w-100"
          renderOnMount
          data-testid={`${props["dropdown-data-testid"]}Menu`}
        >
          <Dropdown.Header>
            <div className="w-100 position-relative d-flex">
              <Form.Control
                autoComplete="off"
                ref={inputRef}
                value={search}
                onChange={handleChange}
                required={required && !value}
                disabled={disabled}
                data-testid={props["input-data-testid"]}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.stopPropagation();
                    e.preventDefault();
                    const newEvent = {
                      target: { value: e.currentTarget.value },
                    } as React.ChangeEvent<HTMLInputElement>;
                    handleChange(newEvent);
                  }
                }}
                onInvalid={() => {
                  setInvalidSelection(true);
                }}
                className="dropdown-input-control"
              />

              <BsSearch
                size="1.3rem"
                className=" text-secondary position-absolute end-0 h-100 me-2 pe-1"
              />
            </div>
          </Dropdown.Header>
          {isLoading ? (
            <Dropdown.Item
              className=" d-flex justify-content-center align-items-center"
              data-testid={`${props["dropdown-data-testid"]}Loading`}
            >
              <LoadingComponent />
            </Dropdown.Item>
          ) : (
            options.map((item, index) => (
              <Dropdown.Item
                title={renderTitle(item)}
                key={`${renderName(item)}: ${index}`}
                as="option"
                className={`${
                  value &&
                  renderName(item) === renderName(value) &&
                  renderTitle(item) === renderTitle(value)
                    ? "bg-success bg-opacity-25"
                    : ""
                } dropdown-input-item`}
                value={index}
                onClick={handleClick}
                data-testid={`${props["dropdown-data-testid"]}${renderName(
                  item
                )}`}
              >
                {renderName(item)}
              </Dropdown.Item>
            ))
          )}
        </Dropdown.Menu>
      </Dropdown>
      {invalidSelection && (
        <small className="text-danger mt-1">
          {customFeedbackMessage
            ? customFeedbackMessage
            : "Esse campo é obrigatorio"}
        </small>
      )}
    </div>
  );
}
