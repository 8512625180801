import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CardsCheckin } from './components/CardsCheckins';
import { api } from '../../services/api';
import { CurrentConfigs } from '../../utils/externalPortalUtils';
import { eventGa } from '../../utils/eventClickGa';
import { useTimeout } from 'usehooks-ts';
import { CheckinTable } from './components/CheckinTable';
import { PaginationTable } from '../../components/Pagination';
import { useSearchParams } from 'react-router-dom';
import { t } from 'i18next';

const reactCheckinURL = import.meta.env.VITE_REACTCHECKINURL;

export function Checkins() {
  const [searchParams, setSearchParams] = useSearchParams();
  const configs = CurrentConfigs.get();

  const [checkins, setCheckins] = useState<GetCheckinResponse | null>(null);
  const [coinType, setCoinType] = useState<string>('BRL');
  const [coinPrice, setCoinPrice] = useState<number>();
  const pageNumber = searchParams.get('page');
  const [page, setPage] = useState(Number(pageNumber) ?? 1);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 20;
  const totalItems = checkins?.count ?? 0;

  const isPagination = totalItems > pageSize;

  useEffect(() => {
    setPage(1);
    setSearchParams({ page: '1' });
  }, []);

  async function getCheckins() {
    try {
      setIsLoading(true);
      const response = await api(
        `/stocks/checkins?pageNumber=${page}&itemsPerPage=${pageSize}`
      );
      setCheckins(response.data);
    } catch (err) {
      console.error('An error occurred while fetching checkins:', err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setCoinType(configs?.convertionCoinType || 'BRL');
    setCoinPrice(configs?.convertionCoinPrice);
    getCheckins();
  }, [page]);

  useTimeout(() => {
    eventGa({
      label: localStorage.getItem('gaClient') || 'Not defined',
      action: 'accesso_pagina_checkins',
    });
  }, 1000);

  return (
    <Fragment>
      <Container className="mb-5">
        <Row className="mb-4">
          <Col className="fs-4 ">{t("StockCheckin.stockCheckin")}</Col>
          <Col className="d-flex justify-content-end">
            <Button
              variant="success"
              href={reactCheckinURL}
              onClick={() => {
                eventGa({
                  label: localStorage.getItem('gaClient') || 'Not defined',
                  action: 'clique_botao_enviar_estoque',
                });
              }}
              target="_blank"
            >
              {t("StockCheckin.attributes.sendStockCheckin")}
            </Button>
          </Col>
        </Row>

        <Row className="mb-4 gap-1w-100 ">
          <CardsCheckin
            data={checkins}
            coinPrice={coinPrice}
            coinType={coinType}
          />
        </Row>
        <Row className="mb-4">
          <CheckinTable data={checkins} isLoading={isLoading} />
        </Row>
        <Row>
          {isPagination && checkins?.checkins && (
            <PaginationTable
              itemsPerPage={pageSize}
              page={Number(page)}
              totalItems={totalItems}
              setPage={setPage}
              firstPageIndex={(page - 1) * pageSize + 1}
              lastPageIndex={(page - 1) * pageSize + checkins.checkins?.length}
            />
          )}
        </Row>
      </Container>
    </Fragment>
  );
}
