import { type ReactNode } from "react"
import { Col } from "react-bootstrap"

type SidebarProps = {
  children: ReactNode
}

export function Sidebar({ children }: SidebarProps) {
  return (
    <Col
      className="col-md-2 text-start w-100 col-2"
      style={{
        maxWidth: "200px",
      }}
    >
      {children}
    </Col>
  )
}
