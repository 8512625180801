import { CurrentConfigs } from "../externalPortalUtils";

export default function formatMoney(amount: number): string {
  return amount.toLocaleString("pt-BR", {
    style: "currency",
    currency: CurrentConfigs.get()?.convertionCoinType,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
