export default function dataConverter(data: string) {
    // Divida a string de data nos componentes ano, mês e dia
    const partes = data.split("-");
    
    // Verifique se há três componentes (ano, mês e dia)
    if (partes.length !== 3) {
      return "Data inválida";
    }
    
    // Extraia o ano, mês e dia
    const yaer = partes[0];
    const month = partes[1];
    const day = partes[2];
    
    // Formate a data no formato "dd/mm/aaaa"
    const formattedDate = `${day}/${month}/${yaer}`;
    
    return formattedDate;
  }
  