import { Form, Button } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

type SearchProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onSubmit?: React.FormEventHandler;
  placeholder?: string;
  isLoading?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export function Search({
  onChange,
  onClick,
  onSubmit,
  value,
  placeholder,
  onKeyDown,
}: SearchProps) {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
  };

  return (
    <Form
      onSubmit={onSubmit || ((event) => event.preventDefault())}
      className="d-flex  gap-2"
    >
      <Form.Control
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onSubmit={handleSubmit}
      />
      <Button
        variant="outline-primary"
        onClick={onClick}
        className="d-flex"
        title="Buscar"
      >
        <BsSearch className="mt-1" />
      </Button>
    </Form>
  );
}
