import { useEffect, useState } from "react";
import { Button, Col, Nav, TabContainer } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

interface ButtonGroup {
  label: string;
  variant: string;
  eventKey: string;
  disabled?: boolean;
}

interface ButtonSubsectionProps {
  buttonGroupProps: ButtonGroup[];
  defaultActiveKey?: string;
  children: React.ReactNode;
  onSelect?: (selectedKey: string) => void;
}

export default function ButtonSubsectionRoot({
  buttonGroupProps,
  defaultActiveKey = "",
  children,
  onSelect,
}: ButtonSubsectionProps) {
  const classNameIsNotButton =
    "w-auto me-2 bg-transparent border-0 text-primary";
  const buttonDisabled = "w-auto me-2 bg-transparent border-0 text-secondary";

  const [urlParams, setUrlParams] = useSearchParams();
  const [selectedButton, setSelectedButton] =
    useState<string>(defaultActiveKey);

  useEffect(() => {
    const showParams = urlParams.get("show");
    if (showParams) {
      const selected = buttonGroupProps.find(
        (item) => item.eventKey === showParams
      );
      setSelectedButton(selected?.eventKey || buttonGroupProps[0].eventKey);
    } else {
      setSelectedButton(defaultActiveKey || buttonGroupProps[0].eventKey);
    }
  }, [defaultActiveKey, buttonGroupProps, urlParams]);

  const handleSelectedButton = (eventKey: string) => {
    setUrlParams({ show: eventKey });
    setSelectedButton(eventKey);
    if (onSelect) {
      onSelect(eventKey);
    }
  };

  return (
    <Col className="mt-3" sm={16}>
      <TabContainer id="left-tabs-example" activeKey={selectedButton}>
        <div className="d-flex gap-2 align-items-center mb-4">
          {buttonGroupProps.map((button) => (
            <Nav.Link
              key={button.eventKey}
              eventKey={button.eventKey}
              disabled={button.disabled}
            >
              <Button
                className={
                  selectedButton === button.eventKey
                    ? ""
                    : button.disabled
                    ? buttonDisabled
                    : classNameIsNotButton
                }
                style={{
                  color: selectedButton === button.eventKey ? "" : "#2A5CF8",
                }}
                variant={button.variant}
                onClick={() => handleSelectedButton(button.eventKey)}
              >
                {button.label}
              </Button>
            </Nav.Link>
          ))}
        </div>
        {children}
      </TabContainer>
    </Col>
  );
}
