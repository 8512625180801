import styled, { keyframes } from "styled-components";

const skeletonLoading = keyframes`
  0% {
    background-color: #21212110;
  }
  100% {
    background-color: #21212110;
  }
`;

export const SkeletonContainer = styled.div`
  animation: ${skeletonLoading} 1s linear infinite alternate;
  border-radius: 5px;
`;
