import React, { useContext, createContext, useState } from "react";

interface ExternalKeyContextProps {
  customerExternalKey: Boolean;
  handleCustomerExternalKey: (_value: boolean) => void;
}

interface ExternalKeyProviderProps {
  children: React.ReactNode;
}

export const ExternalKeyContext = createContext({} as ExternalKeyContextProps);
export function ExternalKeyProvider({ children }: ExternalKeyProviderProps) {
  const [customerExternalKey, setCustomerExternalKey] = useState(false);
  const [token, setToken] = useState("");

  function handleToken(value: string) {
    setToken(value);
  }

  function handleCustomerExternalKey(value: boolean) {
    setCustomerExternalKey(value);
  }

  return (
    <ExternalKeyContext.Provider
      value={{
        customerExternalKey,
        handleCustomerExternalKey,
      }}
    >
      {children}
    </ExternalKeyContext.Provider>
  );
}
