import { Container, Col, Row, Nav } from "react-bootstrap";
import { BsBoxArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo_recoloca.svg";
import { AuthTokensManager } from "../../utils/authUtils";
import { logout } from "../../services/auth.service";
import { ErrorTemplateProps } from "./ErrorTemplate";
import { HttpStatusCode } from "axios";

export function ErrorTemplate(props: ErrorTemplateProps) {
  const { errorType, errorDescription, errorTitle, errorSolution } = props;
  const navegate = useNavigate();

  const handleLogout = async () => {
    AuthTokensManager.cleanTokens();
    await logout();
    navegate("/login");
  };

  return (
    <>
      <Container className="p-3 bg-dark text-white " fluid>
        <Container>
          <Row>
            <Col className="d-flex align-items-center">
              <Nav.Link
                href="/login"
                className="text-decoration-none my-2 
              my-lg-0 me-lg-auto"
              >
                <img src={logo} width="180" />
              </Nav.Link>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Nav.Link
                onClick={handleLogout}
                style={{ color: "#fff" }}
                className="d-flex flex-column align-items-center"
              >
                <BsBoxArrowRight size={25} />
                <span>Sair</span>
              </Nav.Link>
            </Col>
          </Row>
        </Container>
      </Container>
      <div className="pt-5 mt-5 d-flex flex-column align-items-center">
        <p className="display-1 fw-bolder pt-5">{HttpStatusCode[errorType]}</p>
        <p className=" fs-2 fw-bold">{errorTitle}</p>
        <p className=" fs-5 pb-4">{errorDescription}</p>
        <p>{errorSolution}</p>
      </div>
    </>
  );
}
