import { api } from "./api";
import { BASE_URL } from "./auth.service";

export function externalPortalList(search: string) {
  return api.get(
    `${BASE_URL}/customers_groups/external_panel_configs/base_data?search=${search}`
  );
}

export function getExternalPortal({ id }: { id: number }) {
  return api.get(`${BASE_URL}/customers_groups/external_panel_configs/${id}`);
}
