interface MoneyProps {
    valueCents: string; 
    coinPrice: number ;
    coinType: string;
  }
  
  export function FormatCurrencyValue(props: MoneyProps): JSX.Element {
    const { valueCents, coinPrice, coinType } = props; 
  const value = Number(valueCents);
  const  convertionNumber = (value / Number(coinPrice)).toLocaleString("pt-BR", {
    style: "currency",
    currency: coinType,
  });
  const parsedValue = Number(
    convertionNumber.replace(/[^\d,-]/g, "").replace(",", ".")
  );
  const formattedValue =
    parsedValue < 0 ? `(-) ${convertionNumber.slice(1)}` : convertionNumber;

  return <div>{formattedValue}</div>; 
}