import { Outlet, useSearchParams } from "react-router-dom";
import { Header } from "../components/Header";
import { SubHeader } from "../components/SubHeader";
import { useEffect, useState, useContext } from "react";

import { AuthTokensManager } from "../utils/authUtils";
import { ExternalKeyContext } from "../context/CustomerExternalKeyContext";
import { Spinner } from "react-bootstrap";
import { loginRedirect } from "../utils/redirectUtils/redirectUtils";
import { ScreenLoading } from "../components/ScreenLoading";

import { MultiPortalsDropdown } from "./components/MultiPortalsDropdown";
import {
  externalPortalList,
  getExternalPortal,
} from "../services/externalPortal.service";

import { CurrentConfigs } from "../utils/externalPortalUtils";

import * as Sentry from "@sentry/react";
import { useLocalStorage} from "usehooks-ts";

export function LayoutDefault() {
  const [params] = useSearchParams();
  const { handleCustomerExternalKey } = useContext(ExternalKeyContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasMultiPortal, setHasMultiPortal] = useState(false);
  const [actualPortal, setActualPortal] = useState<ExternalPortal>();
  const [externalPortals, setExternalPortals] = useState<Array<ExternalPortal>>(
    []
  );
  const [isGooxxer, setIsGooxxer] = useState(false);
  const [configIsLoading, setConfigIsLoading] = useState(false);
  const [, setGaClient] = useLocalStorage("gaClient", "");

  const baseData = async (search: string = "") => {
    if (params.has("auth_token")) AuthTokensManager.setTokensByParams();
    else if (!AuthTokensManager.tokensExist()) loginRedirect();
    else {
      try {
        const { data } = await externalPortalList(search);
        setIsGooxxer(!data.isExternal);
        if (data.externalPanelConfigs && data.externalPanelConfigs.length > 1)
          setHasMultiPortal(true);
        if (!actualPortal) {
          const actualConfig = CurrentConfigs.get();
          if (actualConfig) {
            setActualPortal(
              data.externalPanelConfigs.find(
                (item: ExternalPortal) => item.id === actualConfig.id
              )
            );
          } else {
            setActualPortal(data.externalPanelConfigs[0]);
          }
        }
        setExternalPortals(data.externalPanelConfigs);
        setIsAuthenticated(true);

        Sentry.setUser({
          id: localStorage.getItem("uid") || "Not defined",
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    baseData();
  }, []);

  useEffect(() => {
    localStorage.setItem("isGooxxer", isGooxxer.toString());
  }, [isGooxxer]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    baseData(e.target.value);
  };

  useEffect(() => {
    const getPortal = async () => {
      setConfigIsLoading(true);
      try {
        const actualConfig = CurrentConfigs.get();
        if (!actualPortal || actualConfig?.id === actualPortal.id) return;
        const { data } = await getExternalPortal({ id: actualPortal.id });
        CurrentConfigs.set(data);
        document.title = "Recoloca | " + data.customersGroup.name;
        if (data.customerExternalKey) {
          handleCustomerExternalKey(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setConfigIsLoading(false);
      }
    };
    getPortal();
    setGaClient(actualPortal?.title || "not defined");
  }, [actualPortal]);

  return (
    <>
      {isAuthenticated && CurrentConfigs.get() ? (
        <>
          {isGooxxer && actualPortal ? (
            <MultiPortalsDropdown
              items={externalPortals}
              actualPortal={actualPortal}
              setActualPortal={setActualPortal}
              onSearch={handleSearch}
            />
          ) : (
            <></>
          )}
          <Header />
          <SubHeader
            isLoading={configIsLoading}
            isGooxxer={isGooxxer}
            externalPortals={externalPortals}
            actualPortal={actualPortal}
            setActualPortal={setActualPortal}
          />

          {configIsLoading ? (
            <div className="d-flex w-auto align-items-center  justify-content-center mt-5 pt-5 ">
              <Spinner animation="border" role="status" variant="secondary" />
            </div>
          ) : (
            <Outlet key={CurrentConfigs.get()?.id} />
          )}
        </>
      ) : (
        <ScreenLoading />
      )}
    </>
  );
}
