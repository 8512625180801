import { Container, Col, Row, Card } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { PopoverCustom } from "../PopoverCustom";
import { formatTelephone } from "../../utils/formatTelephone";
import { Skeleton } from "../Skeleton";
import { CurrentConfigs } from "../../utils/externalPortalUtils";
import { MultiCountriesMenu } from "../MultiCountriesMenu";
import { t } from "i18next";

export function SubHeader(props: SubHeaderProps) {
  const {
    isLoading,
    isGooxxer,
    actualPortal,
    setActualPortal,
    externalPortals,
  } = props;
  const config = CurrentConfigs.get();

  return (
    <Container fluid className="mb-4">
      <Col className="d-flex align-items-center border-bottom py-3 ">
        <Container className="">
          <Row>
            <Col className="d-flex align-items-center">
              <div className="pe-3">
                <Skeleton isloading={isLoading}>
                  <img
                    src={config?.logoIconUrl}
                    className="flex justify-content-center align-items-center rounded-2"
                    style={{
                      width: "3em",
                      height: "3em",
                    }}
                  />
                </Skeleton>
              </div>
              <div className="d-flex align-items-end gap-2">
                <Skeleton isloading={isLoading}>
                  <span>
                    <span className="text-dark fs-4 ">{config?.title}</span>{" "}
                    {!isGooxxer && externalPortals?.length > 1 && (
                      <MultiCountriesMenu
                        actualPortal={actualPortal}
                        setActualPortal={setActualPortal}
                        externalPortals={externalPortals}
                      />
                    )}
                  </span>
                </Skeleton>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              {config?.supplierResponsible && (
                <PopoverCustom
                  popoverContent={
                    <div className=" d-flex flex-column m-4">
                      <div className=" d-flex flex-row justify-content-center align-items-center mb-3">
                        <Skeleton isloading={isLoading}>
                          <img
                            src={config.supplierResponsible.profileImageUrl}
                            className="rounded-circle"
                            width="55em"
                          />
                        </Skeleton>
                        <div className=" d-flex flex-column ps-2">
                          <Skeleton isloading={isLoading}>
                            <strong className=" fs-5">
                              {config.supplierResponsible.name}
                            </strong>
                          </Skeleton>
                          <Skeleton isloading={isLoading}>
                            <span>
                              GoBlack {t("Layout.attributes.responsible")}
                            </span>
                          </Skeleton>
                        </div>
                      </div>
                      <span>Email:</span>
                      <p>
                        <Skeleton isloading={isLoading}>
                          <a
                            href={`mailto:${config.supplierResponsible.email}`}
                            target="_blanck"
                          >
                            {config.supplierResponsible.email}
                          </a>
                        </Skeleton>
                      </p>
                      <span>Telefone:</span>
                      <Skeleton isloading={isLoading}>
                        <p>
                          {formatTelephone(
                            config.supplierResponsible.telephone
                          )}
                        </p>
                      </Skeleton>
                      <span>Whatsapp</span>

                      <Skeleton isloading={isLoading}>
                        <a
                          href={`https://wa.me/${config.supplierResponsible.telephone}?text=Olá! venho através do Portal do parceiro, gostaria de solucionar uma dúvida.`}
                          target="_blanck"
                        >
                          enviar mensagem
                        </a>
                      </Skeleton>
                    </div>
                  }
                  variant=""
                  placement="bottom-end"
                  className="p-0 border-0 bg-transparent d-flex flex-column justify-content-center align-items-end gap-1"
                >
                  <Skeleton isloading={isLoading}>
                    <span>GoBlack {t("Layout.attributes.responsible")}</span>
                  </Skeleton>
                  <Skeleton isloading={isLoading}>
                    <strong>{config.supplierResponsible.name}</strong>
                  </Skeleton>
                </PopoverCustom>
              )}
            </Col>
          </Row>
        </Container>
      </Col>
    </Container>
  );
}
