export const extractNameFromEmail = (email: string): string => {
  if (!email) {
    return ""
  }
  const parts = email.split("@")[0].split(".")
  const capitalizedParts = parts.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1),
  )
  return capitalizedParts.join(" ")
}
