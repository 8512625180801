import { useState, useEffect } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { PaginationProps } from "./pagination";
import { ButtonPagination, PaginationContainer } from "./styles";
import { t } from "i18next";

export function PaginationTable(props: PaginationProps) {
  const {
    firstPageIndex,
    itemsPerPage = 20,
    lastPageIndex,
    routerLink = null,
    totalItemPage,
    totalItems,
    setPage,
    page,
  } = props;
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const totalPages = Math?.ceil(Number(totalItems) / itemsPerPage);

  useEffect(() => {
    if (Number(page) !== currentPage) {
      setCurrentPage(Number(page));
    }
  }, [page, currentPage]);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    setPage(pageNumber);
    routerLink && window.location.replace(`${routerLink}${pageNumber}`);
  };

  const renderPageNumbers = () => {
    const visiblePages = [];

    if (totalPages <= 5) {
      visiblePages.push(...pageNumbers);
    } else {
      if (currentPage <= 2) {
        visiblePages.push(...pageNumbers.slice(0, 4), "...", totalPages);
      } else if (currentPage >= totalPages - 1) {
        visiblePages.push(1, "...", ...pageNumbers.slice(totalPages - 3));
      } else {
        visiblePages.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }

    return (
      <>
        {visiblePages.map((pageNumber, index) => {
          const variant =
            pageNumber === currentPage ? "primary" : "outline-secondary";
          const disabled = pageNumber === "..." ? true : false;

          return (
            <ButtonPagination
              key={index}
              size="sm"
              pagenumber={String(pageNumber)}
              variant={variant}
              className="border"
              onClick={() => handlePageChange(Number(pageNumber))}
              disabled={disabled}
            >
              {pageNumber}
            </ButtonPagination>
          );
        })}
      </>
    );
  };

  return (
    <PaginationContainer>
      <div>
        <p className="text-md text-gray-700">
          {t("Global.attributes.displaying")}{" "}
          <span className="fw-bold">{firstPageIndex}</span>
          <span> - </span>
          <span className="fw-bold">{lastPageIndex}</span>
          <span> {t("Global.attributes.from")} </span>
          <span className="fw-bold">{totalItems}</span>{" "}
          {t("Global.attributes.registers")}
        </p>
      </div>
      <div>
        <nav className="" aria-label="Pagination">
          <ButtonPagination
            onClick={() => handlePageChange(currentPage - 1)}
            size="sm"
            variant="outline-secondary"
            className="border"
            disabled={currentPage === 1}
          >
            <BsChevronLeft />
          </ButtonPagination>

          {renderPageNumbers()}

          <ButtonPagination
            size="sm"
            onClick={() => handlePageChange(currentPage + 1)}
            variant="outline-secondary"
            disabled={currentPage === totalPages}
            className="border"
          >
            <BsChevronRight />
          </ButtonPagination>
        </nav>
      </div>
    </PaginationContainer>
  );
}
