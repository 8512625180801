import React from "react";
import { Alert } from "react-bootstrap";
import {
  BsFillCheckCircleFill,
  BsFillExclamationTriangleFill,
} from "react-icons/bs";

type AlertProps = {
  variant: "success" | "danger" | "warning";
  show: boolean | undefined;
  onClose: () => void;
  dismissible?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export function CustomAlert({
  variant,
  show,
  onClose,
  dismissible,
  children,
  className = "",
}: AlertProps) {
  return (
    <Alert
      variant={variant}
      show={show ?? false}
      onClose={onClose}
      dismissible={dismissible}
      className={`alert-dismissible fade ${className}`}
    >
      {variant === "success" ? (
        <BsFillCheckCircleFill data-testid="success-icon" className="mx-1" />
      ) : (
        <BsFillExclamationTriangleFill
          data-testid="danger-icon"
          className="mx-1"
        />
      )}
      {children}
    </Alert>
  );
}
