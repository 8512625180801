import React, { useState, useRef, useEffect } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

export function PopoverCustom(
  props: React.PropsWithChildren<PopoverCustomProps>
) {
  const {
    popoverContent,
    variant = "outline-primary",
    className,
    placement,
    children,
    buttonSize,
    title,
    disabled = false,
  } = props;

  const [showPopover, setShowPopover] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (!buttonRef.current || !popoverRef.current) return;

    const isClickInsideButton = buttonRef.current.contains(
      event.target as Node
    );
    const isClickInsidePopover = popoverRef.current.contains(
      event.target as Node
    );

    if (!isClickInsideButton && !isClickInsidePopover) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const popover = (
    <Popover id="popover-basic">
      <div onClick={(e) => e.stopPropagation()} ref={popoverRef}>
        {popoverContent}
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={placement}
      overlay={popover}
      show={showPopover}
      onToggle={(nextShow) => setShowPopover(nextShow)}
    >
      <Button
        ref={buttonRef}
        title={title}
        variant={variant}
        size={buttonSize}
        disabled={disabled}
        className={className}
        onClick={() => setShowPopover(!showPopover)}
      >
        {children}
      </Button>
    </OverlayTrigger>
  );
}
