import { Fragment, useEffect, useState } from "react";
import {
  BsFillFileEarmarkExcelFill,
  BsFileEarmarkPdfFill,
} from "react-icons/bs";
import { Button, Col, Container, Row } from "react-bootstrap";
import { InvoiceCard } from "../../../../components/InvoicesCard";
import { CustomTable } from "../../../../components/Table/CustomTable";
import { Colum } from "../../../../components/Table/CustomTable";
import moment from "moment";
import { Search } from "../../../../components/Search";

import {
  invoiceList,
  downloadInvoicePDF,
  downloadInvoiceXML,
} from "../../../../services/invoice.service";
import { CurrentConfigs } from "../../../../utils/externalPortalUtils";
import { formatWeight } from "../../utils/formatWeight";
import { eventGa } from "../../../../utils/eventClickGa";
import { useTimeout } from "usehooks-ts";
import { FormatCurrencyValue } from "./components/FormatCurrencyValue";
import { t } from "i18next";
import { FiltersInvoices } from "./components/FiltersInvoices";

export function NotaFiscal() {
  const params = new URLSearchParams(window.location.search);
  const searchParam = params.get("search") ? String(params.get("search")) : "";
  const [search, setSearch] = useState("");
  const [searchControl, setSearchControl] = useState("");
  const [data, setData] = useState<InvoiceData | null>(null);
  const [searchSupplier] = useState<string>("0");
  const [searchBuyer, setSearchBuyer] = useState<string>("0");
  const [searchShow] = useState<string>("");
  const [invoicesCount, setInvoicesCount] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState<number>(Number(params.get("page")) || 1);
  const [itemsPerPage] = useState<number>(20);
  const [coinType, setCoinType] = useState<string>("BRL");
  const [coinPrice, setCoinPrice] = useState<number | null>(null);
  const [monthFilter, setMonthFilter] = useState<string>("");
  const configs = CurrentConfigs.get();

  const columns: Array<Colum<Invoice>> = [
    {
      name: t("Global.attributes.date"),
      size: 1,
      field: (item) => moment.utc(item?.emmitedAt).format("DD/MM/YYYY"),
    },
    {
      name: t("Invoices.attributes.orderNumber"),
      size: 1,

      field: (item) => item.invoiceOrder,
    },
    {
      name: t("Invoices.attributes.emitter"),
      tdClassName: "character-limit-buttom",
      size: 2,
      field: (item) =>
        item.supplier?.corporateName
          ? item.supplier?.corporateName
          : "Não informado",
      title: (item) =>
        item.supplier?.corporateName
          ? item.supplier?.corporateName
          : "Não informado",
    },
    {
      name: t("Invoices.attributes.receiver"),
      tdClassName: "character-limit-buttom",
      size: 3,
      field: (item) =>
        item.buyer?.corporateName ? item.buyer?.corporateName : "Não informado",
      title: (item) =>
        item.buyer?.corporateName ? item.buyer?.corporateName : "Não informado",
    },
    {
      name: t("Invoices.attributes.netWeight"),
      thClassName: "text-end",
      tdClassName: "text-end",
      field: (item) => formatWeight(item.netWeight),
    },
    {
      name: t("Invoices.attributes.grossWeight"),
      thClassName: "text-end",
      tdClassName: "text-end",
      field: (item) => formatWeight(item.grossWeight),
    },
    {
      name: t("Global.attributes.value"),
      direction: "end",
      size: 1,
      field: (item) => (
        <FormatCurrencyValue
          valueCents={item.valueCents as string}
          coinPrice={coinPrice as number}
          coinType={coinType}
        />
      ),
    },
    {
      name: t("Global.attributes.actions"),
      direction: "center",
      size: 1,
      field: (item) => (
        <div
          className="btn-group btn-group-sm text-end"
          role="group"
          aria-label="Basic outlined example"
        >
          <Button
            disabled={item.receiptUrl === null}
            variant="outline-primary"
            className="button-table d-flex py-2"
            size="sm"
            title={
              item.receiptUrl === null
                ? "NF indisponível"
                : t("Invoices.attributes.visualizeXml")
            }
            onClick={() => {
              let id = Number(item.id);
              item?.receiptUrl !== null ? downloadInvoiceXML({ id }) : "";
            }}
          >
            <BsFillFileEarmarkExcelFill />
          </Button>
          <Button
            disabled={item.receiptUrl === null}
            variant="outline-primary"
            className="button-table d-flex py-2"
            size="sm"
            title={
              item.receiptUrl === null
                ? "NF indisponível"
                : t("Invoices.attributes.visualizePdf")
            }
            onClick={() => {
              let id = Number(item.id);
              item?.receiptUrl !== null ? downloadInvoicePDF({ id }) : "";
            }}
          >
            <BsFileEarmarkPdfFill />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
    params.set("page", "1");
  }, []);

  useEffect(() => {
    if (searchControl !== "" && (data?.totalValue as number) < 20) {
      setPage(1);
    }
  }, [searchControl, monthFilter, itemsPerPage, page]);

  useEffect(() => {
    if (configs) {
      setCoinType(configs.convertionCoinType);
      setCoinPrice(configs.convertionCoinPrice);
    }
  }, [configs]);

  useEffect(() => {
    setIsLoading(true);
    async function fetchData(): Promise<void> {
      try {
        const response = await invoiceList({
          buyerId: Number(searchBuyer) === 0 ? "" : searchBuyer,
          supplierId: Number(searchSupplier) === 0 ? "" : searchSupplier,
          itemsPerPage: String(itemsPerPage),
          search: searchControl,
          emmitedAtYearMonth: monthFilter,
          gooxxySupplier: searchShow,
          pageNumber: page,
        });
        setData(response);
        setInvoicesCount(response.count);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [
    searchSupplier,
    searchBuyer,
    monthFilter,
    searchShow,
    page,
    searchControl,
  ]);

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    setSearchControl(search);
  };

  useTimeout(() => {
    eventGa({
      label: localStorage.getItem("gaClient") || "Not defined",
      action: "acesso_pagina_notas_fiscais",
    });
  }, 1000);

  return (
    <Fragment>
      <Container>
        <Row className="pb-4 align-items-center">
          <Col className="col-auto fs-4">{t("Invoices.invoices")}</Col>
        </Row>
        <Row>
          <Col className="col-2">
            <FiltersInvoices
              setSearchBuyer={setSearchBuyer}
              handleMonth={setMonthFilter}
            />
          </Col>
          <Col>
            <Row className="gap-3">
              <InvoiceCard
                data={data}
                isLoading={isLoading}
                coinPrice={coinPrice}
                coinType={coinType}
              />
            </Row>
            <Row>
              <Search
                value={search}
                placeholder={t("Invoices.attributes.orderNumber")}
                onChange={(e) => setSearch(e.target.value)}
                onClick={handleSearch}
                onSubmit={handleSearch}
              />
            </Row>
            <Row className="character-limit-">
              <CustomTable
                items={data?.invoices ?? []}
                columns={columns}
                isLoading={isLoading}
                totalItems={invoicesCount}
                setPage={setPage}
                page={page}
                itemsPerPage={itemsPerPage}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
