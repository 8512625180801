/* eslint-disable @typescript-eslint/no-unsafe-call */

import {CaretDown, CaretRight, CaretUp} from 'phosphor-react';
import {useState} from 'react';

type DropdownMenu = {
	text?: any;
	open?: any;
	view?: boolean;
};
export function SidebarButton({open, text, view}: DropdownMenu) {
	const [views, setViews] = useState(false);

	function handleView() {
		// SetSelectedButtonId(0);
		setViews(!views);
		open(!view);
	}

	return (

		<button
			className=' btn-toggle text-start justify-content-start rounded collapsed  d-flex'
			data-bs-toggle='collapse'
			data-bs-target='#home-collapse' aria-expanded='false'
			onClick={handleView}>
			{!view
				? <CaretRight size={20} weight='bold' />
				: <CaretDown size={20} weight='bold' />}
			{text}
		</button>

	);
}
