import logoRecoloca from "../../assets/img/logo_recoloca.svg";
import {
  Container,
  Col,
  Navbar,
  Spinner,
  Stack,
  Button,
  Card,
  Form,
  Nav,
} from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import bgDarkLogo from "../../assets/img/bg-dark-logo.svg";
import msLogo from "../../assets/img/ms-logo.svg";
import { emailValidation } from "../../utils/emailValidation";
import { CustomAlert } from "../../components/CustomAlert";
import { LoadingComponent } from "../../components/Loading";
import { login, validateToken } from "../../services/auth.service";
import { AxiosError, AxiosRequestHeaders, HttpStatusCode } from "axios";
import { AuthTokensManager } from "../../utils/authUtils";
import { recolocacoesRedirect } from "../../utils/redirectUtils/redirectUtils";
import { ScreenLoading } from "../../components/ScreenLoading";

export function Login() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [currentUserIsLoading, setCurrentUserIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [unexpectedError, setUnexpectedError] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await login({
        email: userEmail,
        password: userPassword,
      });

      AuthTokensManager.setTokensByHeader(
        response.headers as AxiosRequestHeaders
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.Unauthorized) {
          setUnauthorized(true);
        } else {
          console.error(error);
          setUnexpectedError(true);
        }
      } else console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.currentTarget.checkValidity() ? handleLogin() : setIsValidated(true);
  };

  const checkCurrentUser = async () => {
    setCurrentUserIsLoading(true);
    try {
      const request = await validateToken();
      if (request.data.success) {
        recolocacoesRedirect();
      }
    } catch (error) {
      console.log(error);
      setCurrentUserIsLoading(false);
    }
  };

  useEffect(() => {
    if (AuthTokensManager.tokensExist()) {
      checkCurrentUser();
    }
  }, []);

  return (
    <>
      {currentUserIsLoading ? (
        <ScreenLoading />
      ) : (
        <Container
          fluid
          className="background-login d-flex flex-column justify-content-between align-items-center min-vh-100"
        >
          <Navbar expand="lg" className="w-100 p-2">
            <Navbar.Brand href="#home">
              <img src={logoRecoloca} alt="" style={{ width: "180px" }} />
            </Navbar.Brand>
            <Navbar.Toggle className="bg-success" />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text className="d-flex gap-4 text-light sm-flex-column">
                <NavLink
                  to="https://www.gooxxy.com/"
                  className="text-light text-md"
                  target="_blank"
                >
                  Site
                </NavLink>
                <NavLink
                  to="https://www.instagram.com/gooxxybrasil/"
                  className="text-light text-md"
                  target="_blank"
                >
                  Instagram
                </NavLink>
              </Navbar.Text>
            </Navbar.Collapse>
          </Navbar>
          <Col lg={4} md={6} sm={10}>
            <Card className="d-flex flex-column align-items-center p-5 ">
              <img
                className="img-fluid rounded-circle mb-3"
                src={bgDarkLogo}
                width="80em"
              ></img>
              <p>Acesse sua conta Gooxxy</p>
              <p className=" fs-3 fw-bold">Entrar</p>
              <Form
                noValidate
                validated={isValidated}
                onSubmit={handleSubmit}
                className="w-100"
              >
                <CustomAlert
                  onClose={() => {
                    setUnauthorized(false);
                  }}
                  variant="danger"
                  show={unauthorized}
                  dismissible={true}
                >
                  Email ou senha incorretos!
                </CustomAlert>
                <CustomAlert
                  onClose={() => {
                    setUnexpectedError(false);
                  }}
                  variant="danger"
                  show={unexpectedError}
                  dismissible={true}
                >
                  Um erro aconteceu, por favor, entre em contato com o
                  administrador do sistema.
                </CustomAlert>
                <Form.Group className="mb-4">
                  <Form.Label>
                    E-mail <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    type="email"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Este campo é obrigatório. Preencha-o corretamente.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>
                    Senha <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    type="password"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Este campo é obrigatório
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  type="submit"
                  variant="success"
                  className="w-100"
                  size="lg"
                >
                  {isLoading ? <LoadingComponent /> : "Acessar"}
                </Button>
              </Form>
            </Card>
          </Col>
          <div className="w-100 p-4">
            <Stack className="col-md-5 mx-auto text-light text-center">
              <span className="text-white-50">
                Developed by <strong>GoTech</strong>.
              </span>
            </Stack>
          </div>
        </Container>
      )}
    </>
  );
}
