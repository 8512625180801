import { Container, Dropdown, Form, Nav } from "react-bootstrap";
import { CustomDropdown } from "../../../components/CustomDropdown";
import { CurrentConfigs } from "../../../utils/externalPortalUtils";
import { DropdownInput } from "../../../components/DropdownInput";
import { Trans } from "react-i18next";
import { t } from "i18next";

export function MultiPortalsDropdown(props: multiPortalsDropdownProps) {
  const { items, actualPortal, setActualPortal, onSearch } = props;
  return (
    <div className="bg-success">
      <Container className="text-white d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start py-2">
        <div>
          <Trans i18nKey={"Layout.attributes.phrase"}>
            Você esta acessando o <strong> Portal do Parceiro </strong>
          </Trans>{" "}
          (
          <Nav.Link
            href={import.meta.env.VITE_RECOLOCAURL}
            className=" d-inline text-decoration-underline"
            target="_blank"
          >
            {t("Layout.attributes.accessRecoloca")}
          </Nav.Link>
          )
        </div>
        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start">
          <span className="text-nowrap me-md-3">{t("Layout.attributes.selectPartner")}</span>
          <DropdownInput
            options={items}
            onClick={(item) => {
              setActualPortal(item);
              CurrentConfigs.set(undefined);
            }}
            renderName={(item) => item.title}
            onChange={onSearch}
            value={actualPortal}
            selectIcon={false}
          />
        </div>
      </Container>
    </div>
  );
}
