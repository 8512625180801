import { useState } from "react";
import { Card, Modal, Row } from "react-bootstrap";
import { MultiCountriesCard } from "./MultiCountriesCard";

export function MultiCountriesMenu(props: MultiCountriesMenuProps) {
  const { actualPortal, setActualPortal, externalPortals } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      <span>
        (
        <a
          onClick={() => setShow(true)}
          className="btn text-decoration-underline text-primary p-0 border-0"
        >
          alterar
        </a>
        )
      </span>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <span className="fs-5 text-secondary">Seleciona a unidade</span>
          <div className="gap-2 pt-3 d-flex flex-column">
            {externalPortals.map((portal) => (
              <MultiCountriesCard
                portal={portal}
                selected={portal.id === actualPortal?.id}
                setPortal={setActualPortal}
              />
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
