import { Fragment } from "react";
import { Card, Col } from "react-bootstrap";
import { SkeletonCard } from "../../../../utils/SkeletonCard";
import { CurrentConfigs } from "../../../../utils/externalPortalUtils";
import { t } from "i18next";

function formatMoney(amount: number): string {
  return amount.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function RepurposalsCards({
  totalizers,
  repurposals,
  isLoading,
  coinType,
  coinPrice,
}: RepurposalsCards) {
  const customerExternalKey = CurrentConfigs.get()?.customerExternalKey;

  return (
    <Fragment>
      <div className="mb-4 d-flex gap-2">
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Repurposals.attributes.repurposalQuantity")}</Card.Title>
              <Card.Text>{repurposals?.count ?? "0"}</Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Repurposals.attributes.buyersQuantity")}</Card.Title>
              <Card.Text>{totalizers?.buyersCount ?? "0"}</Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Repurposals.attributes.averageTicket")}</Card.Title>
              <Card.Text>
                {customerExternalKey
                  ? `€ ${
                      repurposals && totalizers && repurposals.count !== 0
                        ? formatMoney(
                            Number(
                              coinPrice
                                ? totalizers &&
                                    Number(totalizers.amount / coinPrice)
                                : totalizers?.amount ?? "0"
                            ) / repurposals?.count
                          )
                        : "0,00"
                    }`
                  : `R$ ${
                      repurposals && totalizers && repurposals.count !== 0
                        ? formatMoney(
                            Number(
                              coinPrice
                                ? totalizers &&
                                    Number(totalizers.amount / coinPrice)
                                : totalizers?.amount ?? "0"
                            ) / repurposals?.count
                          )
                        : "0,00"
                    }`}
              </Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Global.attributes.amount")}</Card.Title>
              <Card.Text>
                {customerExternalKey
                  ? `€ ${formatMoney(
                      Number(
                        coinPrice
                          ? totalizers && Number(totalizers.amount / coinPrice)
                          : totalizers?.amount ?? "0"
                      )
                    )}`
                  : `R$ ${formatMoney(Number(totalizers?.amount ?? "0"))}`}
              </Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
      </div>
    </Fragment>
  );
}
