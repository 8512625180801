import { t } from "i18next";

export const REPORT_KINDS_TRANSLATION = [
  {
    key: "operational",
    value: "Reports.types.operational",
  },
  {
    key: "impact",
    value: "Reports.types.impact",
  },
  {
    key: "sustainability_indicators",
    value: "Reports.types.sustainabilityIndicators",
  },
  {
    key: "release",
    value: "Reports.types.release",
  },
  {
    key: "inspection",
    value: "Reports.types.inspection",
  },
];

export function getReportValue(key: string) {

   const item = REPORT_KINDS_TRANSLATION.find((report) => report.key === key)?.value;
   return item ? item : "-";
}
