import { Fragment, useEffect, useState } from 'react';
import CheckinTableRow from './components/CheckinTableRow';
import { TableEmptyState } from '../../../../components/TableEmptyState';
import CheckinTableHead from './components/CheckinTableHead';
import { Table } from 'react-bootstrap';
import { TableSkeleton } from '../../../../utils/TableSkeleton';

export function CheckinTable({ data, isLoading }: StockCheckinProps) {
  return (
    <>
      <Fragment>
        <Table
          responsive
          className="table-hover mt-3 mb-3"
          data-testid="stock-checkin-table"
        >
          <CheckinTableHead />
          <tbody className="align-middle">
            {isLoading ? (
              <TableSkeleton cols={7} />
            ) : data?.checkins?.length === 0 ? (
              <TableEmptyState />
            ) : (
              <CheckinTableRow checkins={data ? data.checkins : []} />
            )}
          </tbody>
        </Table>
      </Fragment>
    </>
  );
}
