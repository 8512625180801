import { ErrorTemplate } from "../../../components/ErrorTemplate";

export function InternalServerError() {
  return (
    <ErrorTemplate
      errorType="InternalServerError"
      errorTitle="Ocorreu um erro"
      errorDescription="O servidor está indisponível no momento."
      errorSolution="Recarregue a tela ou tente novamente mais tarde."
    />
  );
}
