import React, { useState, useEffect, Fragment } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  addMonths,
  isBefore,
} from "date-fns";
import { t } from "i18next";
import { Button } from "react-bootstrap";

interface DateItem {
  label: string;
  value: string;
  startAt?: string;
  endAt?: string;
}

interface DateListProps {
  selectedMonth: number;
  selectedYear: number;
  setMonth?: (month: string) => void;
  setDateRange?: (startAt: string, endAt: string) => void;
  useDateRange?: boolean;
}

export default function MonthFilter({
  selectedMonth,
  selectedYear,
  setMonth,
  setDateRange,
  useDateRange = false,
}: DateListProps) {
  const [dateList, setDateList] = useState<DateItem[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateItem>();

  useEffect(() => {
    const generateDateList = () => {
      const currentDate = new Date();
      const startDate = new Date(selectedYear, selectedMonth - 1, 1);
      let dates: DateItem[] = [];

      let tempDate = startDate;
      while (isBefore(tempDate, currentDate)) {
        const startAt = format(startOfMonth(tempDate), "yyyy-MM-dd");
        const endAt = format(endOfMonth(tempDate), "yyyy-MM-dd");

        dates.push({
          label: `${t(
            `Global.months.${format(tempDate, "MMMM").toLowerCase()}`
          )}/${format(tempDate, "yyyy")}`,
          value: startAt,
          startAt,
          endAt,
        });

        tempDate = addMonths(tempDate, 1);
      }

      setDateList(dates.reverse());
    };

    generateDateList();
  }, [selectedMonth, selectedYear]);

  const handleSelection = (item: DateItem) => {
    setSelectedDate(item);
    if (useDateRange && setDateRange) {
      setDateRange(item.startAt!, item.endAt!); // Passa startAt e endAt se estiver no modo de intervalo
    } else {
      setMonth && setMonth(item.value); // Passa apenas o valor do mês se estiver no modo simples
    }
  };

  return (
    <Fragment>
      <span className="flex-column">
        <Button
          role="button"
          variant="muted"
          style={{
            fontSize: "14px",
            fontWeight: selectedDate === undefined ? "bold" : "normal",
          }}
          onClick={() => {
            setSelectedDate(undefined);
            if (useDateRange && setDateRange) {
              setDateRange("", ""); // Reset no modo de intervalo
            } else {
              setMonth && setMonth(""); // Reset no modo simples
            }
          }}
          className="hover-bold list-unstyled border border-0 small text-start"
        >
          {t("Global.attributes.all") as string}
        </Button>
      </span>

      {dateList?.map((item) => (
        <div key={item.value}>
          <Button
            role="button"
            variant="muted"
            style={{
              fontSize: "14px",
              fontWeight: selectedDate === item ? "bold" : "normal",
            }}
            onClick={() => handleSelection(item)}
            className="hover-bold list-unstyled border border-0 small text-start"
          >
            {item.label}
          </Button>
        </div>
      ))}
    </Fragment>
  );
}
