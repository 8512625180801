export enum GooxxySolutionsValue {
  GoBox = 'packaging',
  GoFast = 'shelf',
  GoInput = 'input',
  GoLast = 'discontinued/overstock',
  GoOut = 'nonconforming',
  GoRestart = 'damage',
}

export const gooxxySolutionsOptions = () => [
  {
    value: GooxxySolutionsValue.GoBox,
    label: 'GoBox',
  },
  {
    value: GooxxySolutionsValue.GoFast,
    label: 'GoFast',
  },
  {
    value: GooxxySolutionsValue.GoInput,
    label: 'GoInput',
  },
  {
    value: GooxxySolutionsValue.GoLast,
    label: 'GoLast',
  },
  {
    value: GooxxySolutionsValue.GoOut,
    label: 'GoOut',
  },
  {
    value: GooxxySolutionsValue.GoRestart,
    label: 'GoRestart',
  },
];

export function convertSolutions(solutions: string): string {
  switch (solutions) {
    case 'packaging':
      return 'GoBox';
    case 'shelf':
      return 'GoFast';
    case 'input':
      return 'GoInput';
    case 'discontinued/overstock':
      return 'GoLast';
    case 'nonconforming':
      return 'GoOut';
    case 'damage':
      return 'GoRestart';
    default:
      return '1 Selecionado';
  }
}
