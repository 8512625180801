import styled, { keyframes } from "styled-components";

const skeletonLoading = keyframes`
  0% {
    background-color: #21212110;
  }
  100% {
    background-color: #21212110;
  }
`;

interface Props {
  isFull: boolean | undefined;
}
export const SkeletonContainer = styled.div<Props>`
  animation: ${skeletonLoading} 1s linear infinite alternate;
  border-radius: 5px;

  ${({ isFull }) => isFull && "width: 100%"}
`;
