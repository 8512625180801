import axios from "axios";
import { removeNullUndefined } from "../utils/removeNullUndefined";
import { api } from "./api";
export const BASE_URL = import.meta.env.VITE_BASEURL;
export const BASE_URL_RAILS = import.meta.env.VITE_RAILS_BASEURL;

type ListInvoicesProps = {
  setData: any;
  search: any;
  supplierId: any;
  buyerId: any;
  searchMonthYear: any;
  searchShow: any;
};

type InvoiceListProps = {
  gooxxySupplier: string;
  supplierId: string;
  buyerId: string;
  search: string;
  emmitedAtYearMonth: string;
  pageNumber: number;
  itemsPerPage: string;
};

export const invoiceList = async (props: InvoiceListProps) => {
  const response = await api(`/billing/invoices`, {
    params: props,
  });
  return response.data;
};

interface DownloadInvoice {
  id: number;
}

export const filtersBuyers = async () => {
  const response = await api.get(`/billing/invoices/buyers `);
  return response;
};

export async function downloadInvoicePDF(props: DownloadInvoice) {
  api(`/billing/invoices/${props.id}/pdf`, {
    method: "GET",
    responseType: "blob",
  })
    .then((response) => response.data)
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      window.open(url);
    });
}

export async function downloadInvoiceXML(props: DownloadInvoice) {
  api(`/billing/invoices/${props.id}/xml`, {
    method: "GET",
    responseType: "blob",
  })
    .then((response) => response.data)
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      window.open(url);
    });
}

export async function listWeightMapData(filters: {
  startAt?: string | null;
  endAt?: string | null;
}) {
  const queryParams = new URLSearchParams(removeNullUndefined(filters));
  const { data } = await api.get(
    `/reports/mesoregions/gross_weight_kg?${queryParams.toString()}`
  );

  return data;
}

export async function getMapGeoJson() {
  const { data } = await axios.get(
    `${BASE_URL_RAILS}/public/geojsons/mesoregions`
  );

  return data;
}
