import { Button, ButtonGroup } from "react-bootstrap";

export function MultiCountriesCard(props: MultiCountriesCardProps) {
  const { selected, portal, setPortal } = props;
  return (
    <Button
      as={selected ? "span" : "button"}
      variant=""
      className={`multi-portal-button d-flex align-items-center border text-start fs-5 p-0 ${
        selected &&
        "bg-secondary bg-opacity-25 border-secondary border-opacity-50 pe-none "
      }`}
      onClick={() => setPortal(portal)}
    >
      <div
        className="flex justify-content-center align-items-center bg-white rounded-start-2 border-end"
        style={{
          width: "5rem",
          height: "5rem",
        }}
      >
        <img
          className="rounded-start-2 object-fit-scale w-100 h-100"
          src={portal.logoIconUrl}
          alt={portal.title}
        />
      </div>

      <span className="ms-2">{portal.title}</span>
    </Button>
  );
}
