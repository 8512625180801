import { Navigate, Route, Routes } from "react-router-dom";
import { LayoutDefault } from "../Layout/Default";
import { Checkins } from "../pages/Checkins";
import { Login } from "../pages/Login";
import { Relatorios } from "../pages/Reports";
import { Repurposals } from "../pages/Repurposals";

import { Comprovantes } from "../pages/Comprovantes";
import { Forbidden } from "../pages/Errors/Forbidden";
import { InternalServerError } from "../pages/Errors/InternalServerError";
import InvoicesTab from "../pages/NotaFiscal";

export function Routers() {
  if (window.location.pathname === "/") {
    window.location.href = "/recolocacoes";
  }
  return (
    <Routes>
      <Route path="/login" index element={<Login />} />
      <Route path="/" element={<LayoutDefault />}>
        <Route path="recolocacoes" element={<Repurposals />} />
        <Route path="relatorios" element={<Relatorios />} />
        <Route path="checkins" element={<Checkins />} />
        <Route path="invoices" element={<InvoicesTab />} />

        <Route path="payments" element={<Comprovantes />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="forbidden" element={<Forbidden />} />
      <Route path="internal-server-error" element={<InternalServerError />} />
    </Routes>
  );
}
