import moment from "moment";
import { Colum, CustomTable } from "../../../../components/Table/CustomTable";
import { CurrentConfigs } from "../../../../utils/externalPortalUtils";
import { STATUS_MAP, getStatusKey } from "../../utils/status/statusMap";
import { t } from "i18next";

function formatMoney(amount: number): string {
  return amount.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function RepurposalsTable({
  data,
  isLoading,
  coinPrice,
  coinType,
  page,
  setPage,
}: RepurposalsTableProps): JSX.Element {
  const customerExternalKey = CurrentConfigs.get()?.customerExternalKey;

  const repurposal: RepurposalsService[] | null = data && data.repurposals;

  const columns: Array<Colum<RepurposalsService>> = [
    {
      name: t("Repurposals.attributes.repurposalNumber"),
      size: 1,
      field: (item) => item.id,
    },
    {
      name: t("Repurposals.attributes.emission"),
      size: "auto",
      field: (item) => moment(item.emittedAt).format("DD/MM/YY HH:mm"),
    },
    {
      size: 1,
      name: t("Repurposals.attributes.sapCode"),  
      field: (item) => (item && item.sapCode) ?? "-",
      shouldDisplay: !!customerExternalKey,
    },
    {
      size: 5,
      name: t("Global.attributes.buyer"),
      tdClassName: "character-limit",
      title: (item) => item.buyerName,
      field: (items) => (items.buyerName === "" ? "-" : items.buyerName),
    },
    {
      size: "auto",
      name: t("Global.attributes.status"),
      field: (items) => <>{getStatusKey(items.status)}</>,
    },
    {
      direction: "end",
      name: t("Global.attributes.value"),
      field: (items) =>
        customerExternalKey
          ? `€ ${formatMoney(
              coinPrice ? items.grossValue / coinPrice : items.grossValue
            )}`
          : `R$ ${formatMoney(items.grossValue)}`,
    },
  ];

  if (!repurposal) return <></>;

  return (
    <CustomTable
      columns={columns}
      items={repurposal}
      isLoading={isLoading}
      itemsPerPage={data?.itemsPerPage}
      totalItems={data?.count}
      page={data?.pageNumber}
      setPage={setPage}
    />
  );
}
