import { Container, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import CardsComprovantes from "../../components/CardsPayments";
import { Search } from "../../components/Search";
import { CustomTable, Colum } from "../../components/Table/CustomTable";
import converterData from "../../utils/DataConverter";
import { BsFileEarmarkArrowDownFill } from "react-icons/bs";
import {
  paymentsListFetch,
  downloadPayment,
  PaymentsList,
} from "../../services/payments.service";
import { Payments } from "../../services/payments.service";
import { PaymentsFilters } from "./Components/PaymentsFilters";
import { CurrentConfigs } from "../../utils/externalPortalUtils";
import { eventGa } from "../../utils/eventClickGa";
import { useTimeout } from "usehooks-ts";
import { t } from "i18next";

export function Comprovantes() {
  const params = new URLSearchParams(window.location.search);
  const [data, setData] = useState<Payments>({ pageNumber: 1 } as Payments);
  const [monthStatus, setMonthStatus] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchFinal, setSearchFinal] = useState("");
  const [search, setSearch] = useState("");
  const [paymentsCount, setPaymentsCount] = useState<number>();
  const configs = CurrentConfigs.get();
  const [coinType, setCoinType] = useState<string>("BRL");
  const [coinPrice, setCoinPrice] = useState<number | null>(null);
  const [searchPaymentsType, setSearchPaymentsType] = useState<string>("");

  const [page, setPage] = useState<number>(Number(params.get("page")) || 1);
  const [itemsPerPage] = useState<number>(20);

  useEffect(() => {
    setPage(1);
    params.set("page", "1");
  }, []);

  useEffect(() => {
    if (
      (monthStatus !== "" || searchFinal !== "") &&
      (data?.totalValue as number) < 20
    ) {
      setPage(1);
    }
  }, [searchFinal, monthStatus, itemsPerPage, page]);

  useEffect(() => {
    async function dataFeched() {
      try {
        setIsLoading(true);
        const response = await paymentsListFetch({
          pageNumber: page || 1,
          itemsPerPage: itemsPerPage,
          paymentType: searchPaymentsType,
          paidOnMonthYear: monthStatus,
          search: searchFinal,
        });
        setData(response);
        setPage(response.pageNumber);
        setPaymentsCount(response.count);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    dataFeched();
  }, [page, monthStatus, searchPaymentsType, searchFinal]);

  useEffect(() => {
    if (configs) {
      setCoinType(configs.convertionCoinType);
      setCoinPrice(configs.convertionCoinPrice);
    }
  }, [configs]);

  const paymentsList: PaymentsList[] = data.payments;

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    setSearchFinal(search);
  };

  const columns: Array<Colum<PaymentsList>> = [
    {
      name: t("Global.attributes.date"),
      field: (item) => converterData(item.paidOn),
    },
    {
      name: t("Invoices.attributes.orderNumber"),
      field: (item) => item.orderNumber,
    },
    {
      name: t("Billing.attributes.paymentMethod"),
      field: (item) => item.paymentType.name,
      direction: "center",
    },
    {
      name: t("Billing.attributes.payer"),
      tdClassName: "character-limit-buttom",
      size: 3,
      field: (item) =>
        item.buyer?.corporateName ? item.buyer?.corporateName : "Não informado",
      title: (item) =>
        item.buyer?.corporateName ? item.buyer?.corporateName : "Não informado",
    },

    {
      name: t("Billing.attributes.favored"),
      tdClassName: "character-limit-buttom",
      size: 3,

      field: (item) =>
        item.supplier?.corporateName
          ? item.supplier?.corporateName
          : t("Global.attributes.notInformed"),
      title: (item) =>
        item.supplier?.corporateName
          ? item.supplier?.corporateName
          : t("Global.attributes.notInformed"),
    },
    {
      name: t("Global.attributes.value"),
      direction: "end",
      field: (item) => {
        const formattedValue = (
          Number(item?.value) / Number(coinPrice)
        ).toLocaleString("pt-BR", {
          style: "currency",
          currency: coinType,
        });
        return formattedValue;
      },
    },
    {
      name: t("Global.attributes.actions"),
      direction: "end",
      field: (item) => (
        <div
          className="btn-group btn-group-sm text-end"
          role="group"
          aria-label="Basic outlined example"
        >
          <Button
            disabled={item.receiptUrl === null}
            variant="outline-primary"
            className="button-table d-flex py-2"
            size="sm"
            title={
              item.receiptUrl === null
                ? t("Billing.attributes.unavailableBilling")
                : t("Billing.attributes.visualizeBilling")
            }
            onClick={() => {
              let id = item.id;
              item?.receiptUrl !== null ? downloadPayment({ id }) : "";
            }}
          >
            <BsFileEarmarkArrowDownFill />
          </Button>
        </div>
      ),
    },
  ];

  useTimeout(() => {
    eventGa({
      label: localStorage.getItem("gaClient") || "Not defined",
      action: "acesso_pagina_comprovantes",
    });
  }, 1000);

  return (
    <>
      <Container>
        <Row className="pb-4 align-items-center">
          <Col className="col-auto fs-4"> {t("Billing.billing")}</Col>
        </Row>
        <Row>
          <Col className="col-2">
            <PaymentsFilters
              handleMonth={setMonthStatus}
              handleTypePayments={setSearchPaymentsType}
            />
          </Col>
          <Col>
            <Row className="gap-3">
              <CardsComprovantes
                payments={data?.count}
                totalValue={data?.totalValue}
                isLoading={isLoading}
                coinPrice={coinPrice}
                coinType={coinType}
              />
            </Row>
            <Row>
              <Search
                value={search}
                placeholder={t("Invoices.attributes.orderNumber")}
                onChange={(e) => setSearch(e.target.value)}
                onClick={handleSearch}
                onSubmit={handleSearch}
              />
            </Row>
            <Row>
              <CustomTable
                items={paymentsList}
                columns={columns}
                isLoading={isLoading}
                totalItems={paymentsCount}
                setPage={setPage}
                page={page}
                itemsPerPage={itemsPerPage}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
