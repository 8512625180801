import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { ButtonSubsection } from "../../components/ButtonSubsection";
import { NotaFiscal } from "./Components/Invoices";
import Maps from "./Maps";
import { useTranslation } from "react-i18next";

export default function InvoicesTab() {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const defaultKey = urlParams.get("show") || "Invoices";

  const buttonGroup = [
    {
      eventKey: "Invoices",
      label: t("Invoices.invoices"),
      variant: "primary",
    },
    {
      eventKey: "Map",
      label: t("Maps.attributes.mapsDestiny"),
      variant: "primary",
    },
  ];

  const tabContentsGroup = [
    {
      component: <NotaFiscal />,
      eventKey: "Invoices",
    },
    {
      component: <Maps />,
      eventKey: "Map",
    },
  ];

  useEffect(() => {
    const currentKey = urlParams.get("show");
    if (currentKey !== defaultKey) {
      urlParams.set("show", defaultKey);
      window.history.replaceState(null, "", `?show=${defaultKey}`);
    }
  }, [defaultKey]);

  const handleSelect = (selectedKey: string) => {
    urlParams.set("show", selectedKey);
    window.history.replaceState(null, "", `?show=${selectedKey}`);
  };

  return (
    <Container>
      <ButtonSubsection.Root
        defaultActiveKey={defaultKey}
        buttonGroupProps={buttonGroup}
        onSelect={handleSelect}
      >
        <ButtonSubsection.Content tabContents={tabContentsGroup} />
      </ButtonSubsection.Root>
    </Container>
  );
}
