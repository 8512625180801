import moment from "moment";
import CheckinTableActionButtons from "../ActionButtons";
import { Fragment, useState } from "react";
import { Button, Col, Collapse, Table } from "react-bootstrap";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import CheckinTableHead from "../CheckinTableHead";
import { extractNameFromEmail } from "../../../../../../utils/extractNameFromEmail";
import { convertSolutions } from "../../../../../../utils/gooxxySolutionsEnum";
import formatMoney from "../../../../../../utils/FormatMoney";
import { t } from "i18next";

interface CheckinTableRowProps {
  checkins?: StockCheckin[];
}

export default function CheckinTableRow(props: CheckinTableRowProps) {
  const { checkins } = props;

  const dashOrName = (input: string): string => {
    let newInput = input;
    if (input.includes("@gooxxy.com") || input.includes("gooxxy.com")) {
      newInput = extractNameFromEmail(input);
    }
    return newInput;
  };

  const formatSolutionsText = (solutions: GooxxySolutions[]) => {
    return solutions
      .map((solution) => convertSolutions(solution.code))
      .join(", ");
  };

  function generateColumns(checkin: StockCheckin, subcheckin: boolean) {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <>
        <tr>
          <td>
            {!subcheckin ? (
              <Button
                disabled={subcheckin}
                variant="link"
                onClick={() => setIsOpen(!isOpen)}
                data-testid={`collapse-button-${checkin.totalValue}`}
              >
                {isOpen ? <BsChevronDown /> : <BsChevronRight />}
              </Button>
            ) : (
              <div className="bg-white"></div>
            )}
          </td>
          <td>{moment(checkin.createdAt).format("DD/MM/YY - HH:mm")}</td>
          <td>{checkin.id}</td>
          <td className={"character-limit"}>
            {checkin.createdByName ? dashOrName(checkin.createdByName) : "-"}
          </td>
          {checkin.supplier === null ? (
            <td
              className={"character-limit text-danger fst-ita"}
              title={checkin.supplierName}
            >
              {checkin.supplierName === "" ? "-" : checkin.supplierName}
            </td>
          ) : (
            <td className={"character-limit"} title={checkin.supplier?.name}>
              {checkin.supplier?.name === "" ? "-" : checkin.supplier?.name}
            </td>
          )}
          <td className={"character-limit"}>
            {checkin.supplier === null ? (
              <span className="text-secondary fst-italic">
                Ag. identificação{" "}
              </span>
            ) : checkin.supplier?.supplierResponsible === null ? (
              "-"
            ) : (
              checkin.supplier?.supplierResponsible?.name
            )}
          </td>
          <td>
            {checkin.gooxxySolutions.length
              ? formatSolutionsText(checkin.gooxxySolutions)
              : "-"}
          </td>
          <td className={"text-end"}>
            {checkin.totalValue === "0.0" || checkin.totalValue === null
              ? "-"
              : formatMoney(Number(checkin?.totalValue))}
          </td>
          <td className={"text-end"}>
            <CheckinTableActionButtons item={checkin} />
          </td>
        </tr>
        <>
          <tr className="m-0 p-0">
            <td className="m-0 bg-light p-0" colSpan={9}>
              <Collapse className="m-0 p-0" in={isOpen}>
                <div className="bg-white rounded-bottom-4 pt-3 pb-5">
                  <Table responsive className="table-hover  m-0 p-0 bg-white">
                    {checkin.subCheckins?.length > 0 ? (
                      <CheckinTableHead />
                    ) : (
                      <></>
                    )}
                    <tbody className="align-middle bg-white m-0">
                      {checkin.subCheckins?.map(
                        (subcheckin: StockCheckin, index: number) => {
                          return (
                            <Fragment key={index}>
                              {generateColumns(subcheckin, true)}
                            </Fragment>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                  <div>
                    {!checkin.subCheckins?.length && (
                      <div className="d-flex mb-3 bg-white rounded-bottom-4">
                        <Col className="text-start p-3 align-content-center">
                          <p className="ms-3 pt-1">
                            {t("StockCheckin.attributes.noSubcheckin")}
                          </p>
                        </Col>
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </td>
          </tr>
        </>
      </>
    );
  }

  return (
    <>
      {checkins?.map((checkin: StockCheckin) => {
        return <>{generateColumns(checkin, false)}</>;
      })}
    </>
  );
}
