import { ReactNode } from "react"
import { Tab } from "react-bootstrap"

interface TabContent {
  component: ReactNode
  eventKey: string
}

type TabContentsProps = {
  tabContents: TabContent[]
}

export function TabContent({ tabContents }: TabContentsProps) {
  return (
    <Tab.Content className="mt-3">
      {tabContents.map((tab) => (
        <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey}>
          {tab.component}
        </Tab.Pane>
      ))}
    </Tab.Content>
  )
}
