import { useState, useEffect } from "react";

import { t } from "i18next";
import MonthFilter from "../../../../../components/MonthFilter";
import { Filters } from "../../../../../components/Filters";
import { filtersBuyers } from "../../../../../services/invoice.service";
import { SidebarButton } from "../../../../../components/Sidebar/SidebarButton";
import { Sidebar } from "../../../../../components/Sidebar";

interface IFiltersInvoices {
  handleMonth: React.Dispatch<React.SetStateAction<string>>;
  setSearchBuyer: React.Dispatch<React.SetStateAction<string>>;
}

export function FiltersInvoices(props: IFiltersInvoices) {
  const [openMonth, setOpenMonth] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [buyerFilter, setBuyerFilter] = useState([]);

  useEffect(() => {
    async function fetchFilters() {
      try {
        setLoadingFilters(true);
        const responseBuyer = await filtersBuyers();
        setBuyerFilter(responseBuyer.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingFilters(false);
      }
    }
    void fetchFilters();
  }, []);

  const arrayBuyer =
    buyerFilter &&
    buyerFilter?.map((item: BuyerFilterProps) => ({
      value: item.id,
      list: item.name,
    }));

  const handleBuyerFilterChange = (value: string) => {
    props.setSearchBuyer(value);
  };

  return (
    <Sidebar>
      <Filters
        title={t("Global.attributes.buyer")}
        array={arrayBuyer}
        isLoading={loadingFilters}
        handleValue={handleBuyerFilterChange}
      />
      <SidebarButton
        text={t("Global.filters.monthYear") as string}
        open={setOpenMonth}
        view={openMonth}
      />
      {openMonth && (
        <>
          <MonthFilter
            selectedMonth={5}
            selectedYear={2023}
            setMonth={props.handleMonth}
          />
        </>
      )}
    </Sidebar>
  );
}
