import { Col, Card, Button } from "react-bootstrap";
import { getReportDate } from "../../utils/getReportDate";
import { t } from "i18next";

export function ReportCard(props: { report: PortalReport }) {
  const { initialDate, finalDate, reportFileUrl } = props.report;
  const { year, month } = getReportDate(initialDate, finalDate);

  return (
    <Col className="col-lg-4 mb-4">
      <Card
        className="p-4 w-100"
        style={{ minWidth: "290px", maxWidth: "100%" }}
      >
        <Card.Title
          className="fw-bold first-uppercase"
          data-testid={`reportCardMonth'${month}'`}
        >
          {month}
        </Card.Title>
        <Card.Text data-testid={`reportCardYear'${year}'`}>{year}</Card.Text>
        <Button
          variant="success"
          className="p-2"
          onClick={() => window.open(reportFileUrl, "_blank")}
          data-testid={`reportCardButton`}
        >
          {t("Reports.attributes.visualize")}
        </Button>
      </Card>
    </Col>
  );
}
