export class CurrentConfigs {
  public static set(data: ConfigsData | undefined): void {
    sessionStorage.setItem("configsData", JSON.stringify(data));
  }

  public static get(): ConfigsData | undefined {
    let configsData;
    try {
      const sessionData = sessionStorage.getItem("configsData");
      configsData = sessionData ? JSON.parse(sessionData) : undefined;
    } catch (e) {
      configsData = undefined;
    }
    return configsData;
  }

  public static getCustomersGroupId(): number | undefined {
    const configsData = CurrentConfigs.get();
    const id = configsData?.customersGroup?.id;
    return id;
  }
}
