import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ptBRJson from "./translations/ptBR.json";
import enJson from "./translations/en.json";
import esJSon from "./translations/es.json";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      ptBR: ptBRJson,
        en: enJson,
        es: esJSon
    },
    fallbackLng: "ptBR",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;