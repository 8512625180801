import { Card, Col } from "react-bootstrap";
import { Skeleton } from "../../../../components/Skeleton";
import { t } from "i18next";

export function CardsCheckin(props: CardsCheckinProps) {
  const { data, coinPrice, coinType } = props;
  let valorTotal = data ? data.totalValue : 0;

  if (coinPrice && valorTotal) {
    valorTotal /= coinPrice;
  }

  return (
    <>
      <Col>
        <Skeleton isloading={Boolean(!data)}>
          <Col className="w-100">
            <Card className="text-center p-3 border w-full">
              <Card.Title>{data && data.count}</Card.Title>
              <Card.Text>Checkins</Card.Text>
            </Card>
          </Col>
        </Skeleton>
      </Col>
      <Col>
        <Skeleton isloading={Boolean(!data)} isFull>
          <Col className="w-100">
            <Card className="text-center p-3 border w-full">
              <Card.Title>
                {data &&
                  valorTotal &&
                  (valorTotal / Number(data.count)).toLocaleString("pt-br", {
                    style: "currency",
                    currency: coinType,
                  })}
              </Card.Title>
              <Card.Text>
                {t("StockCheckin.attributes.averageCheckinTicket")}
              </Card.Text>
            </Card>
          </Col>
        </Skeleton>
      </Col>
    </>
  );
}
