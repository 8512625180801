export function loginRedirect() {
  window.location.replace(`/login`);
}
export function forbiddenRedirect() {
  window.location.replace(`/forbidden`);
}
export function internalServerErrornRedirect() {
  window.location.replace(`/internal-server-error`);
}
export function recolocacoesRedirect() {
  window.location.replace(`/recolocacoes`);
}
