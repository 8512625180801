import { Card, Col } from "react-bootstrap";
import { SkeletonCard } from "../../utils/SkeletonCard";
import { t } from "i18next";

type CardsPaymentsProps = {
  totalValue: number;
  payments?: number;
  isLoading?: boolean;
  coinPrice?: number | null;
  coinType?: string;
};

export default function CardsPayments({
  totalValue,
  payments,
  isLoading,
  coinPrice,
  coinType,
}: CardsPaymentsProps) {
  if (coinPrice) {
    totalValue /= coinPrice;
  }

  return (
    <>
      <div className="mb-4 d-flex gap-2">
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Billing.attributes.billingQuantity")}</Card.Title>
              <Card.Text>{payments}</Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Global.attributes.amount")}</Card.Title>
              <Card.Text>
                {" "}
                {`${
                  totalValue?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: coinType,
                  }) ?? "0"
                }`}
              </Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
      </div>
    </>
  );
}
