import { api } from "./api";

type RepurposalsListParams = {
  params: {
    createdAtMonthYear: string;
    status: string;
    itemsPerPage: string;
    pageNumber: string;
  };
};

export async function repurposalsList({ params }: RepurposalsListParams) {
  const response = await api.get(
    `/crm/repurposals?createdAtMonthYear=${params.createdAtMonthYear}&status=${params.status}&itemsPerPage=${params.itemsPerPage}&pageNumber=${params.pageNumber}`
  );

  return response.data;
}
type RepurposalsTotalizersParams = {
  params: {
    createdAtMonthYear: string;
    status: string;
  };
};

export async function repurposalsTotalizers({
  params,
}: RepurposalsTotalizersParams) {
  const response = await api.get(
    `/crm/repurposals/totalizers?createdAtMonthYear=${params.createdAtMonthYear}&status=${params.status}`
  );

  return response.data;
}
