import { OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { WeightMapProps } from "../../maps";

export default function WeightMap(props: WeightMapProps) {
  const { width, height, data, scale, numData, loading } = props;

  const svgRef = useRef<SVGSVGElement>(null);
  const [mapReady, setMapReady] = useState(false); // Estado para verificar se o mapa está pronto

  const formatValue = (value: number) => {
    return Math.round(value).toLocaleString("pt-BR");
  };

  function formatTooltip(value: number): string {
    return new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const zoomHandler = d3
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent([1, 8])
      .on("zoom", (event) => {
        svg.selectAll("path").attr("transform", event.transform);
      });

    svg.call(zoomHandler);

    return () => {
      svg.on(".zoom", null); // Remove o evento de zoom ao desmontar
    };
  }, []);

  useEffect(() => {
    // Verifica se os dados estão prontos
    if (!loading && data && numData) {
      setMapReady(true); // Define como pronto apenas quando os dados estão disponíveis e o loading acabou
    }
  }, [loading, data, numData]);

  const values = Object.values(numData || {})
    .map((value) =>
      isNaN(value) || value === undefined || value === null ? 0 : value
    )
    .filter((value) => value >= 0);

  const numBreaks = 5;

  const quantileScale = d3
    .scaleQuantile()
    .domain(values)
    .range(d3.range(numBreaks + 1));

  const breaks = quantileScale.quantiles();

  const colorScale = d3
    .scaleThreshold<number, string>()
    .domain(breaks)
    .range(d3.schemeGreens[numBreaks]);

  const projection = d3
    .geoMercator()
    .scale(scale)
    .center([-52, -15])
    .translate([width / 2, height / 2]);

  const geoPathGenerator = d3.geoPath().projection(projection);

  const allSvgPaths = data?.features?.map((shape, index) => {
    const regionCode = shape?.properties?.["GEOCODIGO"];
    let regionData = numData ? numData[regionCode] : 0;

    // Se não houver dados ou se os dados forem inválidos, definimos a cor como branca
    const color = regionData > 0 ? colorScale(regionData) : "#fff"; // Branco para regiões sem dados, verde para as com dados

    // Garantir que o key seja único usando "index" se "id" não estiver disponível
    const shapeId = shape.id || regionCode || index;

    return (
      <OverlayTrigger
        key={shapeId} // Use shapeId como key, garantindo que é único
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${shapeId}`}>
            {shape?.properties?.["MESO"]}
            {regionData !== undefined
              ? `: ${formatTooltip(regionData)} Kg`
              : ""}
          </Tooltip>
        }
      >
        <path
          d={geoPathGenerator(shape) as string}
          stroke="black"
          strokeWidth={0.5}
          fill={color} // Preenchimento com cor baseada nos dados ou branco
          fillOpacity={0.8}
        />
      </OverlayTrigger>
    );
  });

  function generateLegend() {
    const legendWidth = 100;
    return (
      <div className="d-flex align-items-center justify-content-center mt-1">
        {Array.from({ length: numBreaks }, (_, i) => {
          const startValue = isNaN(breaks[i - 1] as number)
            ? 0
            : (breaks[i - 1] as number);
          const endValue = isNaN(breaks[i] as number)
            ? 0
            : (breaks[i] as number);

          return (
            <div key={i} className="align-items-center">
              <div
                style={{
                  width: `${legendWidth}px`,
                  height: "15px",
                  backgroundColor: d3.schemeGreens[numBreaks][i],
                }}
              />
              <span className="ms-2" style={{ fontSize: "0.75rem" }}>
                {i === 0
                  ? `≤ ${formatValue(endValue)}`
                  : `${formatValue(startValue)} - ${formatValue(endValue)}`}
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {loading || !mapReady ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: height }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center mt-4">
            <svg ref={svgRef} width={width} height={height}>
              {allSvgPaths}
            </svg>
          </div>
          <div className="mb-5">
            <Row>
              <p className="text-center">Peso bruto (kg) por Mesorregião</p>
              <div>{generateLegend()}</div>
            </Row>
          </div>
        </>
      )}
    </>
  );
}
