/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { CaretDown, CaretRight } from "phosphor-react";
import { useState } from "react";
import { Button } from "react-bootstrap";

interface FilterProps {
  title?: string;
  array?: Array<any>;
  value?: string;
  list?: string;
  isLoading?: boolean;
  handleValue?: (value: string) => void;
  selectedValue?: string;
}

export function Filters(props: FilterProps) {
  const { title = "Title", array, value, handleValue } = props;
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);

  function handleOpen() {
    setOpen(!open);
  }

  function handleClickItem(itemValue: string) {
    setSelectedValue(itemValue);
    handleValue && handleValue(itemValue);
  }

  const newArray =
    array &&
    array?.map((item) => {
      return {
        value: item.value,
        list: item.list,
      };
    });

  return (
    <>
      <button
        className={`btn-toggle text-start justify-content-start rounded collapsed d-flex ${
          open ? "open" : ""
        }`}
        data-bs-toggle="collapse"
        data-bs-target="#home-collapse"
        aria-expanded="false"
        onClick={handleOpen}
      >
        {!open ? (
          <CaretRight size={20} weight="bold" />
        ) : (
          <CaretDown size={20} weight="bold" />
        )}
        {title}
      </button>
      {open && (
        <div className="d-flex flex-column mt-2">
          <Button
            role="button"
            variant="muted"
            onClick={() => handleClickItem("")}
            style={{
              fontSize: "14px",
              fontWeight: selectedValue ? "normal" : "bold",
            }}
            className={`button-menu hover-bold list-unstyled border border-0 small text-start ${
              selectedValue ? "" : "selected"
            }`}
          >
            Todos
          </Button>
          {newArray?.map((item) => {
            const itemValue = item?.value as string;
            const isSelected = itemValue === selectedValue;
            return (
              <div key={itemValue}>
                <Button
                  role="button"
                  variant="muted"
                  onClick={() => handleClickItem(itemValue)}
                  style={{
                    fontSize: "14px",
                    fontWeight: isSelected ? "bold" : "normal",
                  }}
                  className={`button-menu hover-bold list-unstyled border border-0 small text-start ${
                    isSelected ? "selected" : ""
                  }`}
                >
                  {item?.list}
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
