import { Row } from "react-bootstrap";
import { Fragment } from "react";
import { ReportCard } from "../ReportCard";
import {
  REPORT_KINDS_TRANSLATION,
  getReportValue,
} from "../../utils/reportsKind";
import { t } from "i18next";

export function ReportsCards(props: ReportCardsProps) {
  const { name, files } = props;

  return (
    <>
      <div className=" py-4 fs-4" data-testid={`kind'${name}'`}>
        {t(getReportValue(name))}
      </div>
      <Row className="justify-content-center justify-content-lg-start pb-3">
        {files.map((item, index) => (
          <Fragment key={item.name + index}>
            <ReportCard report={item} />
          </Fragment>
        ))}
      </Row>
    </>
  );
}
