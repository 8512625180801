import { Dropdown } from "react-bootstrap";
import {
  STATUS_MAP,
  getStatusKey,
} from "../../pages/Repurposals/utils/status/statusMap";
import { t } from "i18next";
import { useState } from "react";

interface StatusProps {
  selectedStatus: string;
  handleSearch: (status: string) => void;
}

type Status = { key: string; value: string };

export default function Status(props: StatusProps) {
  const [selectedStatus, setSelectedStatus] = useState<string>(
    getStatusKey(props.selectedStatus ?? "")
  );

  const handleStatusChange = (status: Status) => {
    props.handleSearch(status.value);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline-secondary"
        id="dropdown-basic"
        className="border"
      >
        {t("Global.attributes.status")}: {t(selectedStatus) as string}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {STATUS_MAP.map((status) => (
          <Dropdown.Item
            key={status.value}
            onClick={() => {
              setSelectedStatus(status.key);
              handleStatusChange(status);
            }}
          >
            {t(status.key) as string}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
