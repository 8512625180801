import axios, { HttpStatusCode } from "axios";
import { AuthTokensManager } from "../utils/authUtils";
import {
  internalServerErrornRedirect,
  forbiddenRedirect,
  loginRedirect,
} from "../utils/redirectUtils/redirectUtils";
import { CurrentConfigs } from "../utils/externalPortalUtils";

function createAPIClient() {
  const api = axios.create({
    baseURL: `${
      import.meta.env.VITE_RAILS_BASEURL
    }/external_panel/customers_groups/`,
  });

  api.defaults.headers["Accept-language"] = navigator.language;
  api.defaults.headers["Access-Control-Allow-Origin"] = "*";
  api.defaults.headers["access-token"] = AuthTokensManager.getAccessToken();
  api.defaults.headers["client"] = AuthTokensManager.getClient();
  api.defaults.headers["expirity"] = AuthTokensManager.getExpirity();
  api.defaults.headers["uid"] = AuthTokensManager.getUid();

  api.interceptors.request.use((config) => {
    if (config.baseURL && config.url?.slice(0, 4) !== "http")
      config.url =
        config.baseURL +
        CurrentConfigs.getCustomersGroupId() +
        (config.url || "");
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        if (error.response.status === HttpStatusCode.Unauthorized) {
          AuthTokensManager.cleanTokens();
          loginRedirect();
        } else if (error.response.status === HttpStatusCode.Forbidden) {
          forbiddenRedirect();
        } else if (
          error.response.status === HttpStatusCode.InternalServerError
        ) {
          internalServerErrornRedirect();
        }
        return Promise.reject(error);
      }
    }
  );

  return api;
}

export const api = createAPIClient();
