import { Placeholder, Col, Card, Button, Row } from "react-bootstrap";
import { REPORT_KINDS_TRANSLATION } from "../../utils/reportsKind";
import { ReactNode } from "react";

export function ReportsSkeleton() {
  const TOTAL_CARDS = 3;
  let cards: Array<ReactNode> = [];
  for (let i = 0; i < TOTAL_CARDS; i++) {
    cards[i] = (
      <Col className="col-lg-4 mb-4">
        <Card
          className="p-4 w-100"
          style={{ minWidth: "290px", maxWidth: "100%" }}
        >
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={12} className=" rounded-1" />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={12} className=" rounded-1" />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={12} className=" rounded-1" />
          </Placeholder>
        </Card>
      </Col>
    );
  }
  return (
    <>
      <div className=" py-4 fs-4">
        <Placeholder animation="glow">
          <Placeholder xs={3} className=" rounded-1" />
        </Placeholder>
      </div>
      <Row className="justify-content-center justify-content-lg-start pb-3">
        {cards.map((card) => card)}
      </Row>
    </>
  );
}
