import { useRef, useEffect } from "react";
import { SkeletonContainer } from "./styles";
import { SkeletonProps } from "./skeleton";

export function SkeletonCard(props: SkeletonProps) {
  const { children = "", isloading, isFull } = props;
  const skeletonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parentElement = skeletonRef.current?.parentElement;
    if (parentElement) {
      const { width, height } = parentElement.getBoundingClientRect();
      skeletonRef.current.style.width = `${width}px`;
      skeletonRef.current.style.height = `${height}px`;
    }
  }, []);

  return (
    <>
      {isloading ? (
        <SkeletonContainer isFull={isFull}>
          <div
            title="Skeleton"
            id="Skeleton"
            ref={skeletonRef}
            className="invisible  text-transparent d-flex my-1"
          >
            {children}
          </div>
        </SkeletonContainer>
      ) : (
        <div
          className={` d-flex gap-2  ${props.className} `}
          style={{
            width: props.isFull ? "100%" : "",
          }}
        >
          {children}
        </div>
      )}
    </>
  );
}
