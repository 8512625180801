import { ButtonGroup } from 'react-bootstrap';
import { useState } from 'react';
import { BsFileEarmarkArrowDownFill } from 'react-icons/bs';
import { ActionButton } from '../../../../../../components/ActionButton';

interface CheckinTableActionButtonsProps {
  item: StockCheckin;
}

export default function CheckinTableActionButtons(
  props: CheckinTableActionButtonsProps
) {
  const { item } = props;

  const [copyContent, setCopyContent] = useState(false);

  return (
    <ButtonGroup>
      <ActionButton
        disabled={!item.fileUrl}
        title={'Download'}
        onClick={() => window.open(item.fileUrl, '_blank')}
        data-testid={`download-checkin-file-${item.totalValue}`}
      >
        <BsFileEarmarkArrowDownFill />
      </ActionButton>
    </ButtonGroup>
  );
}
