import { t } from "i18next";

export default function CheckinTableHead() {
  return (
    <>
      <thead>
        <tr>
          <th></th>
          <th className={"col-2"}>{t("Global.attributes.shipment")}</th>
          <th className={"col-1"}>
            <abbr title={t("StockCheckin.attributes.titleN")}>Nº</abbr>
          </th>
          <th>{t("StockCheckin.attributes.submitter")}</th>
          <th>{t("Global.attributes.supplier")}</th>
          <th>GoBlack</th>
          <th>{t("StockCheckin.attributes.solutionType")}</th>
          <th className={"text-end"}>{t("Global.attributes.amount")}</th>
          <th className={"text-end"}>{t("Global.attributes.actions")}</th>
        </tr>
      </thead>
    </>
  );
}
