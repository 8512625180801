import { Table } from "react-bootstrap";
import React from "react";
import { PaginationTable } from "../../Pagination";
import { TableSkeleton } from "../../../utils/TableSkeleton";
import { TableEmptyState } from "../../TableEmptyState";

export type Colum<T> = {
  name?: string;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto";
  direction?: "start" | "center" | "end";
  thClassName?: string;
  tdClassName?: string;
  title?: (item: T) => string;
  field: (item: T) => any;
  shouldDisplay?: boolean;
};

export type CustomTableProps<T> = {
  items: Array<T>;
  columns: Array<Colum<T>>;
  isLoading?: boolean;
  itemsPerPage?: number;
  page?: number;
  setPage?: any;
  totalItems?: number;
};

export function CustomTable<T>(props: CustomTableProps<T>) {
  const {
    items,
    columns,
    isLoading = false,
    itemsPerPage,
    page,
    setPage,
    totalItems,
  } = props;

  return (
    <>
      <Table responsive="lg" className="table-hover tabela-catalago mb-4" hover>
        {!columns.some((item) => item.name === undefined) && (
          <thead>
            <tr>
              {columns.map(
                (item: Colum<T>, index: number) =>
                  item.shouldDisplay !== false && (
                    <th
                      key={`${item.name}: ${index}`}
                      className={`col-${item.size || "auto"} text-${
                        item.direction || "start"
                      } ${item.thClassName || ""}`}
                    >
                      {item.name}
                    </th>
                  )
              )}
            </tr>
          </thead>
        )}
        <tbody className="align-middle">
          {isLoading ? (
            <TableSkeleton cols={columns.length} />
          ) : items?.length > 0 ? (
            items.map((item: T, index: number) => (
              <tr key={index}>
                {columns.map(
                  (colum: Colum<T>, index: number) =>
                    colum.shouldDisplay !== false && (
                      <td
                        key={`${colum.field(item)}: ${index}`}
                        className={`text-${colum.direction || "start"} ${
                          colum.tdClassName || ""
                        }`}
                        title={`${(colum.title && colum.title(item)) || ""}`}
                      >
                        {colum.field(item)}
                      </td>
                    )
                )}
              </tr>
            ))
          ) : (
            <TableEmptyState />
          )}
        </tbody>
      </Table>

      {totalItems && totalItems > 0 && itemsPerPage && page ? (
        <PaginationTable
          itemsPerPage={itemsPerPage}
          page={page}
          totalItems={totalItems}
          setPage={setPage}
          firstPageIndex={(page - 1) * itemsPerPage + 1}
          lastPageIndex={(page - 1) * itemsPerPage + items.length}
        />
      ) : (
        <> </>
      )}
    </>
  );
}
