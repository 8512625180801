import { Fragment } from "react";
import { Card, Col } from "react-bootstrap";
import { SkeletonCard } from "../../utils/SkeletonCard";
import { invoiceList } from "../../services/invoice.service";
import { formatWeight } from "../../pages/NotaFiscal/utils/formatWeight";
import { t } from "i18next";

export function InvoiceCard({
  data,
  isLoading,
  coinPrice,
  coinType,
}: InvoiceCardProps) {
  if (
    coinPrice !== undefined &&
    data?.totalValue !== undefined &&
    coinPrice !== null
  ) {
    data.totalValue /= coinPrice;
  }

  function formatMoney(amount: number): string {
    if (!amount) return "";
    return amount?.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return (
    <Fragment>
      <div className="mb-4 d-flex gap-2 quebrar-linha">
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Invoices.attributes.invoiceQuantity")}</Card.Title>
              <Card.Text>{data?.count ?? 0}</Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Invoices.attributes.netWeight")}</Card.Title>
              <Card.Text>
                {data?.totalNetWeight
                  ? formatWeight(data.totalNetWeight)
                  : "0 kg"}
              </Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Invoices.attributes.grossWeight")}</Card.Title>
              <Card.Text>
                {data?.totalGrossWeight
                  ? formatWeight(data.totalGrossWeight)
                  : "0 kg"}
              </Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
        <Col className="w-100">
          <SkeletonCard isloading={isLoading}>
            <Card className="p-3 text-center border border-muted w-100">
              <Card.Title>{t("Global.attributes.amount")}</Card.Title>
              <Card.Text>
                {`${
                  data?.totalValue?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: coinType,
                  }) ?? "0"
                }`}
              </Card.Text>
            </Card>
          </SkeletonCard>
        </Col>
      </div>
    </Fragment>
  );
}
