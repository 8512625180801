import axios from "axios";
import { api } from "./api";

export const BASE_URL = import.meta.env.VITE_RAILS_BASEURL;
export const PANEL_URL = import.meta.env.VITE_REDIRECT;

export function login({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return axios.post(`${BASE_URL}/auth/sign_in`, {
    email,
    password,
  });
}

export async function logout() {
  return api.delete(`${BASE_URL}/auth/sign_out`);
}

export async function validateToken() {
  return api.get("/auth/validate_token");
}
