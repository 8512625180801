import React from "react";
import { Placeholder } from "react-bootstrap";

interface TableSkeletonProps {
  cols: number;
}

export function TableSkeleton(props: TableSkeletonProps) {
  const { cols } = props;
  const rows: Array<React.ReactElement> = [];

  for (let i = 0; i < 3; i++) {
    const cells: Array<React.ReactElement> = [];
    for (let j = 0; j < cols; j++) {
      cells.push(
        <td key={j}>
          <Placeholder as="p" animation="wave" className="mb-1">
            <Placeholder xs={12} className=" rounded-1" />
          </Placeholder>
        </td>
      );
    }
    rows.push(<tr key={i}>{cells}</tr>);
  }

  return <>{rows}</>;
}
