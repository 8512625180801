import { api } from "./api";
const BASE_URL_RAILS = import.meta.env.VITE_RAILS_BASEURL;

export type Payments = {
  count: number;
  itemsPerPage: number;
  pageNumber: number;
  payments: PaymentsList[];
  totalValue: number;
};

export type PaymentsList = {
  buyer: {
    corporateName?: string;
    document: string;
    isBuyer: boolean;
    isSupplier: boolean;
  };
  buyerDocument: string;
  id: number;
  orderNumber: number;
  paidOn: string;
  paymentType: { id: number; name: string };
  receiptUrl: string;
  supplier?: {
    corporateName?: string;
    document: string;
    isBuyer: boolean;
    isSupplier: boolean;
  };
  supplierDocument: string;
  value: string;
};

interface IPaymentsListFetch {
  pageNumber?: number;
  itemsPerPage?: number;
  buyer?: string;
  paidOnMonthYear: string;
  paymentType?: string;
  search?: string;
}

export async function paymentsListFetch(props: IPaymentsListFetch) {
  const response = await api(`/billing/payments`, {
    params: props
  })
  return response.data;
}

interface IDownloadPayment {
  id: number;
}

export async function downloadPayment(props: IDownloadPayment) {
  api(`/billing/payments/${props.id}/receipt`, {
    method: "GET",
    responseType: "blob",
  })
    .then((response) => response.data)
    .then((blob) => {
      let file = window.URL.createObjectURL(blob);
      window.open(file);
    });
}
