import { Nav } from "react-bootstrap";

export function HeaderButton(props: HeaderButtonProps) {
  const { title, icon, href, onClick } = props;
  return (
    <li>
      <Nav.Link
        onClick={onClick}
        data-testid={props["data-testid"]}
        href={href}
        className="text-light text-decoration-none d-flex flex-column gap-1 cursor-pointer align-items-center"
        title={title}
      >
        {icon}
        {title}
      </Nav.Link>
    </li>
  );
}
