import { t } from "i18next";

export const STATUS_MAP = [
  {
    key: "Repurposals.status.finished",
    value: "finished",
  },
  {
    key: "Repurposals.status.invoiced",
    value: "invoiced",
  },
  {
    key: "Repurposals.status.releasedForInvoicing",
    value: "released_for_invoicing",
  },
  {
    key: "Repurposals.status.releasedForPayment",
    value: "released_for_payment",
  },
  {
    key: "Repurposals.status.awaitingValidationBySupplier",
    value: "awaiting_validation_by_supplier",
  },
  {
    key: "Global.attributes.all",
    value: "",
  },
];

export function getStatusKey(value: string) {
  const key =  STATUS_MAP.find((status) => status.value === value)?.key;
  return key ? t(key) as string : "";
}
