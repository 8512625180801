import { Col, Container, Nav, Row } from "react-bootstrap";
import logo from "../../assets/img/logo_recoloca.svg";
import {
  BsFileEarmarkText,
  BsFilesAlt,
  BsBagCheck,
  BsBasket3,
  BsBoxArrowRight,
  BsBarChartLine,
  BsCoin,
} from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../services/auth.service";
import { AuthTokensManager } from "../../utils/authUtils";
import { emailValidation } from "../../utils/emailValidation";
import { HeaderButton } from "./HeaderButton";
import { t } from "i18next";

export function Header() {
  const navegate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (erro) {
      console.log(erro);
    } finally {
      AuthTokensManager.cleanTokens();
      navegate("/login");
    }
  };
  
  const environment: string = import.meta.env.VITE_AMBIENTE

  return (
    <div
      className={`${ environment === "homolog"
          ? "bg-success border-top"
          : "bg-dark"} px-3 py-2 text-white`
      }
    >
      <Container>
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
          <div className=" d-flex">
            <Nav.Link
              className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"
              href={import.meta.env.VITE_HEADER_IMG}
            >
              <img src={logo} width="180" />
            </Nav.Link>
          </div>
          <Nav className="col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
            <HeaderButton
              href="/checkins"
              icon={<BsBagCheck size={25} />}
              title={t("StockCheckin.abreviation")}
            />
            <HeaderButton
              href="/recolocacoes"
              icon={<BsBasket3 size={25} />}
              title={t("Repurposals.repurposals")}
            />
            <HeaderButton
              href="/payments"
              icon={<BsCoin size={25} />}
              title={t("Billing.abreviation")}
            />
            <HeaderButton
              href="/invoices"
              icon={<BsFileEarmarkText size={25} />}
              title={t("Invoices.invoices")}
            />
            <HeaderButton
              href="/relatorios"
              icon={<BsBarChartLine size={25} />}
              title={t("Reports.reports")}
            />
            <HeaderButton
              icon={<BsBoxArrowRight size={25} />}
              title={t("Global.attributes.exit")}
              onClick={handleLogout}
            />
          </Nav>
        </div>
      </Container>
    </div>
  );
}
