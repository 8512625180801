import { createRoot } from "react-dom/client";
import { App } from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeSentry } from "./initializers/sentry";
import ReactGA from "react-ga4";
const root = createRoot(document.getElementById("root")!);

const production = import.meta.env.VITE_AMBIENTE === "production";
production && ReactGA.initialize(import.meta.env.VITE_GA_TRACKING)

initializeSentry()

root.render(<App />);
