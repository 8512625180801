export function getReportDate(
  initialDate: string,
  finalDate: string
): { year: string; month: string } {
  const initial = new Date(initialDate);
  const final = new Date(finalDate);

  const navigatorLanguage = localStorage.getItem("i18nextLng");

  const getMonth = (date: Date) => {
    if (navigatorLanguage === "en") {
      return date.toLocaleString("en-US", { month: "long" });
    } else if (navigatorLanguage === "es") {
      return date.toLocaleString("es-ES", { month: "long" });
    } else {
      return date.toLocaleString("pt-BR", { month: "long" });
    }
  };

  let month = getMonth(initial);
  if (initial.getMonth() !== final.getMonth()) month += ` - ${getMonth(final)}`;

  let year = initial.getFullYear().toString();
  if (initial.getFullYear() !== final.getFullYear())
    year += ` - ${final.getFullYear()}`;

  return { year, month };
}
