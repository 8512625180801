import { AxiosRequestHeaders } from "axios";
import { recolocacoesRedirect } from "../redirectUtils/redirectUtils";

export class AuthTokensManager {
  public static getAccessToken() {
    return `${localStorage.getItem("access-token")}`;
  }

  public static getClient() {
    return `${localStorage.getItem("client")}`;
  }

  public static getExpirity() {
    return `${localStorage.getItem("expirity")}`;
  }

  public static getUid() {
    return `${localStorage.getItem("uid")}`;
  }

  public static setTokensByParams() {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("auth_token");
    const client = params.get("client_id");
    const expirity = params.get("expiry");
    const uid = params.get("uid");

    if (!accessToken || !client || !expirity || !uid) return;

    localStorage.setItem("access-token", accessToken);
    localStorage.setItem("client", client);
    localStorage.setItem("expirity", expirity);
    localStorage.setItem("uid", uid);
    recolocacoesRedirect();
  }

  public static setTokensByHeader(header: AxiosRequestHeaders) {
    localStorage.setItem("access-token", header["access-token"]);
    localStorage.setItem("client", header.client);
    localStorage.setItem("expirity", header.expirity);
    localStorage.setItem("uid", header.uid);
    recolocacoesRedirect();
  }

  public static tokensExist() {
    return (
      this.getAccessToken() !== "null" &&
      this.getClient() !== "null" &&
      this.getExpirity() !== "null" &&
      this.getUid() !== "null"
    );
  }

  public static cleanTokens() {
    localStorage.removeItem("access-token");
    localStorage.removeItem("client");
    localStorage.removeItem("expirity");
    localStorage.removeItem("uid");
  }
}
